import React, { useState, useEffect } from "react";

//dependencies
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import moment from "moment";
import { http } from "../../../axios-https";
import env from "../../../env";

//redux
//styled components
//components
//svg components
import { ReactComponent as UncheckedSwatch } from "../../../assets/icons/dashboardIcons/GreenUnCheckedBox.svg";
import { ReactComponent as CheckedSwatch } from "../../../assets/icons/dashboardIcons/GreenCheckbox.svg";
//data and utils

ChartJS.register(Tooltip, ArcElement, ChartDataLabels);

const options = {
  responsive: true, // Enable responsiveness
  maintainAspectRatio: false, // Allow chart to stretch to the container size
  cutout: "75%",
  plugins: {
    datalabels: {
      color: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      anchor: "center",
      align: "center",
      offset: 10,
      // font: {
      // weight: "bold",
      // },
      position: "outside",
      formatter: (value, context) => {
        if (context.dataset.data[context.dataIndex] > 5) {
          // Show labels only for slices with a value greater than 5
          return `(${value}%)`;
        } else {
          return null; // Hide labels for slices with a value less than or equal to 5
        }
      },
    },
  },
};

const getPastDays = (noOfDays) => {
  // Get the current date
  const currentDate = new Date();

  // Create an array of seven dates using Array.from
  const datesArray = Array.from({ length: noOfDays }, (_, index) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - (noOfDays - 1 - index));
    return moment(date).format("YYYY-MM-DD");
  });

  // Output the array of dates
  return datesArray;
};

const TransactionCount = () => {
  const [selected, setSelected] = useState("Today");
  const [dateRange, setDateRange] = useState(getPastDays(1));
  const [successful, setSuccessful] = useState(null);
  const [total, setTotal] = useState(null);
  const [failed, setFailed] = useState(null);
  const [days, setDays] = useState(1);

  const getTransactionCount = () => {
    http
      .get(
        `${env.greenbanker_admin_base_url}/transaction-count?startDate=${
          getPastDays(days)[0]
        }&endDate=${getPastDays(days)[getPastDays(days).length - 1]}`
      )
      .then((response) => {
        console.log(response);
        setSuccessful(response.data.data.totalSuccessful);
        setFailed(response.data.data.totalFailed);
        setTotal(response.data.data.total);
      })
      .catch((error) => console.log(error));
  };

  const data = {
    labels: ["Label 1", "Label 2"],
    datasets: [
      {
        data: [successful],
        backgroundColor: ["#0ABD5E", "#FF0000"],
        // borderWidth: 1,
        rotation: 90,
      },
    ],
  };

  useEffect(() => {
    getTransactionCount();
  }, [days]);

  return (
    <>
      <h3 className="ring-chart-title">Transaction count</h3>
      {console.log(dateRange)}
      <div className="chart-container">
        <div style={{ alignSelf: "flex-start" }}>
          <div
            className="ring-chart-label"
            style={{ marginBottom: "20px" }}
            onClick={() => setDays(1)}
          >
            {selected === "Today" ? (
              <CheckedSwatch
                style={{ marginRight: "8px" }}
                onClick={() => {
                  setSelected("Today");
                }}
              />
            ) : (
              <UncheckedSwatch
                style={{ marginRight: "8px" }}
                onClick={() => setSelected("Today")}
              />
            )}
            Today
          </div>
          <div
            className="ring-chart-label"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setSelected("Past 7 days");
              setDays(7);
            }}
          >
            {selected === "Past 7 days" ? (
              <CheckedSwatch
                style={{ marginRight: "8px" }}
                onClick={() => setSelected("Past 7 days")}
              />
            ) : (
              <UncheckedSwatch
                style={{ marginRight: "8px" }}
                onClick={() => setSelected("Past 7 days")}
              />
            )}
            Past 7 days
          </div>
          <div
            className="ring-chart-label"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              setSelected("Past month");
              setDays(31);
            }}
          >
            {selected === "Past month" ? (
              <CheckedSwatch
                style={{ marginRight: "8px" }}
                onClick={() => setSelected("Past month")}
              />
            ) : (
              <UncheckedSwatch
                style={{ marginRight: "8px" }}
                onClick={() => setSelected("Past month")}
              />
            )}
            Past month
          </div>
        </div>

        <div className="pie-chart-container">
          <Doughnut data={data} options={options} />
        </div>

        <div className="chart-legend">
          <h5>Total transactions</h5>
          <h2>{total}</h2>
          <div className="chart-label" style={{ marginBottom: "20px" }}>
            <div
              className="chart-label-swatch"
              style={{
                backgroundColor: "#0ABD5E",
              }}
            ></div>
            {successful} successful
          </div>
          <div className="chart-label">
            <div
              className="chart-label-swatch"
              style={{
                backgroundColor: "#FF0000",
              }}
            ></div>
            {failed} failed
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionCount;
