import styled from "styled-components";

const NavbarStyle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100vw - 216px);
	height: 74px;
	background: #f6f8fa;
	border-bottom: 1px solid #eff1f4;
	font-family: "DM Sans";
	cursor: default;

	.profile-bar {
		display: flex;
		align-items: center;
		margin-right: 29px;
	}

	.account-name {
		display: flex;
		flex-direction: column;
		margin-right: 28px;
		margin-left: 8px;
	}

	h2 {
		margin-left: 43px;
		margin-right: 30px;
		font-weight: 400;
		font-size: 24px;
		color: #203202;
	}

	h5 {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.0987097px;
		color: #000000;
	}

	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.0987097px;
		color: #667085;
	}
`;

export { NavbarStyle };
