import React from "react";
import Avatar from "react-avatar";
import AvatarElement from "./AvatarStyles";

const AvatarImage = (props) => {
  return (
    <AvatarElement>
      <Avatar
        name={props.name}
        size={props.size}
        round={props.round}
        color={props.color}
        style={props.style}
        // textSizeRatio={props.textSizeRatio}
        textMarginRatio={props.textMarginRatio}
      />
    </AvatarElement>
  );
};

export default AvatarImage;
