import { createSlice } from "@reduxjs/toolkit";

const searchQuerySlice = createSlice({
  name: "searchQuery",
  initialState: "",
  reducers: {
    setSearchQuery: (state, action) => `${action.payload}`,
  },
});

//this is for dispatch
export const { setSearchQuery } = searchQuerySlice.actions;

//this is for reducer
export default searchQuerySlice.reducer;
