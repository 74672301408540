import { createSlice } from "@reduxjs/toolkit";

const subAccountsSlice = createSlice({
  name: "subAccounts",
  initialState: {
    institutionName: "",
    institutionId: "",
  },
  reducers: {
    setSubAccounts: (state, action) => {
      state.institutionName = action.payload.institutionName;
      state.institutionId = action.payload.institutionId;
    },
  },
});

//this is for dispatch
export const { setSubAccounts } = subAccountsSlice.actions;

//this is for reducer
export default subAccountsSlice.reducer;
