import React, { useState, useEffect, useRef, forwardRef } from "react";

//dependencies
import env from "../../env";
import { http } from "../../axios-https";
import { useSelector, useDispatch } from "react-redux";

import { formatText, formatCurrency, buildQuery } from "../../utils/utils";

const useGetInstitutionsApi = () => {
  const searchQuery = useSelector((state) => state.searchQuery);
  const [totalInstitutions, setTotalInstitutions] = useState("Fetching");
  const paginationParams = useSelector((state) => state.pagination);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [institutionList, setInstitutionList] = useState([]);

  const getInstitutions = () => {
    setLoading(true);
    http
      .get(
        `${env.greenbanker_admin_base_url}/institutions?size=${paginationParams.rowsPerPage}&page=${paginationParams.currentPage}`
      )
      .then((response) => {
        console.log(response.data.data.content);
        const listItem = response.data.data.content.map((item) => {
          return {
            institutionName: item.name && formatText(item.name),
            institutionEmail: item.email,
            address: item.address && formatText(item.address),
            noOfAccounts: item.totalInstitutionAccounts,
            institutionId: item.id,
            dateCreated: item.dateCreated,
            subAccounts: item.totalAccounts,
            totalUsers: item.totalUsers,
            totalStaff: item.totalStaff,
            contactPersonName: item.contactPersonName,
            contactPersonEmail: item.contactPersonEmail,
            contactPersonPhone: item.contactPersonPhone,
            contactPersonBvn: item.contactPersonBvn,
            contactPersonNin: item.contactPersonNin,
          };
        });

        setPageCount(
          Math.ceil(
            response.data.data.totalElements / paginationParams.rowsPerPage
          )
        );
        setTotalInstitutions(response.data.data.totalElements);
        setInstitutionList([...listItem]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getInstitutions();
  }, [paginationParams]);

  return { loading, institutionList, totalInstitutions, pageCount };
};

export default useGetInstitutionsApi;
