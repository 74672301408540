import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactionType: [],
  transactionStatus: [],
  transactionChannel: [],
  dateRange: { startDate: null, endDate: "" },
};

const filterParametersSlice = createSlice({
  name: "filterParameters",
  initialState,

  reducers: {
    addToTransactionType: (state, action) => {
      state.transactionType.push(action.payload);
    },
    addToTransactionStatus: (state, action) => {
      state.transactionStatus.push(action.payload);
    },
    addToTransactionChannel: (state, action) => {
      state.transactionChannel.push(action.payload);
    },
    deleteFromTransactionType: (state, action) => {
      state.transactionType = state.transactionType.filter(
        (item) => item !== action.payload
      );
    },
    deleteFromTransactionStatus: (state, action) => {
      state.transactionStatus = state.transactionStatus.filter(
        (item) => item !== action.payload
      );
    },
    deleteFromTransactionChannel: (state, action) => {
      state.transactionChannel = state.transactionChannel.filter(
        (item) => item !== action.payload
      );
    },

    addStartDate: (state, action) => {
      state.dateRange.startDate = action.payload;
    },
    addEndDate: (state, action) => {
      state.dateRange.endDate = action.payload;
    },
    clearDateRange: (state, action) => {
      state.dateRange.startDate = "";
      state.dateRange.endDate = "";
    },

    clearFilterParameters: (state) => {
      Object.assign(state, initialState);
    },
  },
});

//this is for dispatch
export const {
  addToTransactionType,
  addToTransactionStatus,
  addToTransactionChannel,
  deleteFromTransactionType,
  deleteFromTransactionStatus,
  deleteFromTransactionChannel,
  addStartDate,
  addEndDate,
  clearDateRange,
  clearFilterParameters,
} = filterParametersSlice.actions;

//this is for reducer
export default filterParametersSlice.reducer;
