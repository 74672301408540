//dependencies
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";
//styled components
//components
import Navbar from "./components/navbar/Navbar";
import SidebarMenu from "./components/sidebarMenu/SidebarMenu";
import PagesWrapper from "./pages/pagesWrapper/PagesWrapper";
import Router from "./routes/router";
//svg components
//data

import "./App.css";
function App() {
  return (
    <main>
      <Router />
    </main>
  );
}

export default App;
