import styled from "styled-components";

const TableStyle = styled.div`
  font-family: "DM Sans";
  margin: 44px 54px 100px 48px;
  cursor: default;
  overflow: visible;

  .table-title {
    margin-bottom: 44px;
  }

  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.01em;
    color: #1c2c02;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2b3241;
  }

  .name-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: default;

    .name-content {
      margin-left: 5px;
      min-width: 70%;
      display: flex;
      flex-direction: column;

      .content-line-1 {
        color: #474747;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
      }
      .content-line-2 {
        color: #7a7a7a;
        font-size: 13px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.5px;
      }
    }
  }

  .custom-dropdown {
    width: 168px;
    height: 40px;
    border: 1px solid #eff1f4;
    border-radius: 8px;
    appearance: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACP0lEQVR4nO3au24TQRTG8T9yuJgiEm1A6dyQFzANtTtq3HB5gbwCRQoEIqnTgRQkaqj8CIgiHRR0FEGESySKKAUwaKWpIhL2cnZ8Zvb7Sae095zRrmc+2yAiIiIiIiIiIiIiIiIiIiIiIiIDcwnYBo5i7QBjhmccZ/8R12E7ro2Z6g3DqXoH3GA4rseZT6/DM8uLfPvHBar6DNyifFPg4Iw1+Gp5oXBOnQAPKddd4Pg/a2Am1Khd4CLlGAGPa85uJtSsBXCN/K0CrxvMbSY0qI/ABvmaAO8bzmwmNKyfwB3yM4vHtqbzmgkt6g/wCLhAHjaBXy1nNfOpZQNVvXQebsaxx7bzfbdsZqtDI8FxuDkrhDSpam3MrAB7HRs6cBZuzgshdWsvro2rz7HgKNzMa4SQpe8/bXfm4CDcNAkhLk5U1VnzQ8eGF4nDTRVC3nTsucoINxP2bNr4htMQsuwbI7tHcWb0UdfLplfK5rJpsHk/wBmL49Ir4KpBL5eB5x17OQRu49Qa8LbjgPvAeuY9JHEFeLGku2nq6KlKJnW4mTvdJ5JIEW5GGZx8sg83q7mGkJzCzSTDdJrEyPARLyqE9OV+3OjaLtDvWG1fX137HgMxNTiGFRdC+rJmECyKCyGew00oMYR4DTehxBDSl5nBSaK4ENKXicHZuLgQ4jXcLEoMId7CzW7pIaQv85rfyLn8JaS0cHM4xBCSOtzsDzmE9PlnxKfxDv4CPHH+50kRERERERERERERERERERERwb+/YGrGcWd8VcYAAAAASUVORK5CYII");
    background-repeat: no-repeat;
    background-position: right 1.2rem top 50%;
    background-size: 0.65rem auto;
    padding: 12px 16px 12px 40px;
    outline: none;
    display: flex;
    position: relative;
    font-family: "DM Sans";
    font-size: 12px;
    background-color: #fff;
  }

  .search-bar-container {
    margin-top: 50.3px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputs-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;

const TableDetailStyle = styled.div`
  font-family: "DM Sans";
  padding: 48px;
  cursor: default;

  .detail-summary-container {
    display: flex;
    justify-content: space-between;
    h1 {
      font-weight: 400;
      font-size: 30px;
      line-height: 45px;
      letter-spacing: 0.01em;
      color: #0c0e13;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2b3241;
    }
  }
  .back-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.16px;
    color: #203202;
    margin-bottom: 25.5px;
    cursor: default;
  }

  .institutionId-wrapper {
    width: 162px;
    height: 63px;
    border: 0.5px solid #dbdfe7;
    text-align: center;
    border-radius: 6px;

    p {
      border-bottom: 0.5px solid #dbdfe7;
    }
    h4 {
      margin-top: 8px;
    }
  }
  .search-bar-container {
    margin-top: 50.3px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputs-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .custom-dropdown {
    width: 168px;
    height: 40px;
    border: 1px solid #eff1f4;
    border-radius: 8px;
    appearance: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACP0lEQVR4nO3au24TQRTG8T9yuJgiEm1A6dyQFzANtTtq3HB5gbwCRQoEIqnTgRQkaqj8CIgiHRR0FEGESySKKAUwaKWpIhL2cnZ8Zvb7Sae095zRrmc+2yAiIiIiIiIiIiIiIiIiIiIiIiIDcwnYBo5i7QBjhmccZ/8R12E7ro2Z6g3DqXoH3GA4rseZT6/DM8uLfPvHBar6DNyifFPg4Iw1+Gp5oXBOnQAPKddd4Pg/a2Am1Khd4CLlGAGPa85uJtSsBXCN/K0CrxvMbSY0qI/ABvmaAO8bzmwmNKyfwB3yM4vHtqbzmgkt6g/wCLhAHjaBXy1nNfOpZQNVvXQebsaxx7bzfbdsZqtDI8FxuDkrhDSpam3MrAB7HRs6cBZuzgshdWsvro2rz7HgKNzMa4SQpe8/bXfm4CDcNAkhLk5U1VnzQ8eGF4nDTRVC3nTsucoINxP2bNr4htMQsuwbI7tHcWb0UdfLplfK5rJpsHk/wBmL49Ir4KpBL5eB5x17OQRu49Qa8LbjgPvAeuY9JHEFeLGku2nq6KlKJnW4mTvdJ5JIEW5GGZx8sg83q7mGkJzCzSTDdJrEyPARLyqE9OV+3OjaLtDvWG1fX137HgMxNTiGFRdC+rJmECyKCyGew00oMYR4DTehxBDSl5nBSaK4ENKXicHZuLgQ4jXcLEoMId7CzW7pIaQv85rfyLn8JaS0cHM4xBCSOtzsDzmE9PlnxKfxDv4CPHH+50kRERERERERERERERERERERwb+/YGrGcWd8VcYAAAAASUVORK5CYII");
    background-repeat: no-repeat;
    background-position: right 1.2rem top 50%;
    background-size: 0.65rem auto;
    padding: 12px 16px 12px 40px;
    outline: none;
    display: flex;
    position: relative;
    font-family: "DM Sans";
    font-size: 12px;
    background-color: #fff;
  }
  .name-container {
    display: flex;
    align-items: center;
    cursor: default;

    .name-content {
      margin-left: 5px;
      width: 65%;
      display: flex;
      flex-direction: column;

      .content-line-1 {
        color: #474747;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
      }
      .content-line-2 {
        color: #7a7a7a;
        font-size: 13px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.5px;
      }
    }
  }
`;

export { TableStyle, TableDetailStyle };
