import styled from "styled-components";

const AccountOverview = styled.div`
	width: 52.5vw;
	min-width: 670px;
	height: 517px;
	background-color: #fbfbfb;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
	border-radius: 12px;
	padding-left: 24px;
	padding-right: 24px;
	font-family: "DM Sans";

	.accounts-overview-title {
		margin-top: 28px;
		margin-bottom: 27px;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.16px;
	}

	.tab-container {
		display: flex;
		gap: 36px;
		position: relative;
		left: -2.4%;
		border-bottom: 0.5px solid #bebdbd;
		cursor: default;
	}

	.graph-title-container {
		display: flex;
		justify-content: space-between;
		margin-top: 28px;
		margin-bottom: 40px;

		.label-active-accounts {
			h3 {
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.16px;
				color: #000000;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: #000000;
			}
		}
		.label-todays-active-accounts {
			h5 {
				font-weight: 500;
				font-size: 10px;
				letter-spacing: 0.08em;
			}

			h3 {
				font-weight: 400;
				font-size: 48px;

				letter-spacing: -0.01em;
				color: #2f4a04;
			}
			p {
				font-weight: 400;
				font-size: 12px;
				color: #545454;
			}
		}
	}

	.chart-title,
	.ring-chart-title {
		margin-top: 24px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.16px;
		color: #000000;
	}

	.ring-chart-title {
		margin-bottom: 28px;
	}

	.chart-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.pie-chart-container {
			width: 250px;
			height: 250px;
			margin: auto;
			margin-top: 50px;
		}

		.ring-chart-label {
			display: flex;
			align-items: center;

			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: #000000;
		}

		.chart-legend {
			margin-right: 30px;
			h5 {
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				text-transform: uppercase;
				color: #000000;
			}

			h2 {
				font-style: normal;
				font-weight: 400;
				font-size: 48px;
				line-height: 72px;
				letter-spacing: -0.01em;
				color: #2f4a04;
			}

			.chart-label {
				display: flex;
				align-items: center;
				color: #000000;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;

				.chart-label-swatch {
					width: 16px;
					height: 16px;
					margin-right: 8px;
				}
			}
		}
	}
`;

const SelectedTab = styled.div`
	background-color: inherit;
	border-radius: 8px 8px 0px 0px;

	display: flex;
	flex-direction: column;
	width: 118px;
	height: 37px;
	font-family: "DM Sans";
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	color: #203202;

	::after {
		content: "";
		width: 118px;
		height: 1px;
		background-color: #203202;
	}

	.option1 {
		background-color: #ecf9d8;
		padding: 10px 0px;
		border-radius: 4px 4px 0px 0px;
		width: 118px;
		height: 36px;
	}
`;

const UnselectedTab = styled.div`
	background-color: inherit;
	padding: 10px 0px;
	border-radius: 4px 4px 0px 0px;
	display: flex;
	flex-direction: column;
	width: 118px;
	height: 32px;
	font-family: "DM Sans";
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	color: #2f4a04;
`;

export { AccountOverview, SelectedTab, UnselectedTab };
