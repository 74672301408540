import React, { useState } from "react";

import {
	SelectedTab,
	UnselectedTab,
} from "./dashboardComponents/accountOverview/AccountOverviewStyles";

const TabSelector = ({ selectedTab, setSelectedTab }) => {
	return (
		<div className='tab-container'>
			<div onClick={() => setSelectedTab("Active accounts")}>
				{selectedTab === "Active accounts" ? (
					<SelectedTab>
						<div className='tab-option option1'>
							<h5>Active Accounts</h5>
						</div>
					</SelectedTab>
				) : (
					<UnselectedTab>
						<div className='tab-option option1'>
							<h5>Active Accounts</h5>
						</div>
					</UnselectedTab>
				)}
			</div>

			<div onClick={() => setSelectedTab("BVN info")}>
				{selectedTab === "BVN info" ? (
					<SelectedTab className='tab-option option2'>
						<div className='tab-option option1'>
							<h5>BVN Info</h5>
						</div>
					</SelectedTab>
				) : (
					<UnselectedTab className='tab-option option2'>
						<div className='tab-option option1'>
							<h5>BVN Info</h5>
						</div>
					</UnselectedTab>
				)}
			</div>

			<div onClick={() => setSelectedTab("Transaction count")}>
				{selectedTab === "Transaction count" ? (
					<SelectedTab className='tab-option option3'>
						<div className='tab-option option1'>
							<h5>Transaction count</h5>
						</div>
					</SelectedTab>
				) : (
					<UnselectedTab className='tab-option option3'>
						<div className='tab-option option1'>
							<h5>Transaction count</h5>
						</div>
					</UnselectedTab>
				)}
			</div>
		</div>
	);
};

export default TabSelector;
