import React, { useState, useRef, forwardRef, useEffect } from "react";

//dependencies
import ReactDatePicker from "react-datepicker";
import { CalendarContainer } from "react-datepicker";
import moment from "moment";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toggleFilter } from "../../../redux/slices/transactionFilterSlice";
import {
  addStartDate,
  addEndDate,
  clearDateRange,
} from "../../../redux/slices/filerParametersSlice";
import { setSearchQuery } from "../../../redux/slices/searchQuerySlice";
//styled components
import { TableStyle } from "../../../pages/TableStyle";
//components
import Input from "../../input/Input";
import DownloadTransactionBtn from "./DownloadTransactionBtn";
//svg components
import { ReactComponent as SearchIcon } from "../../../assets/icons/TableIcons/searchIcon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/TableIcons/filter.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/blackArrowForward.svg";
import { ReactComponent as CloseCalendarIcon } from "../../../assets/icons/TableIcons/greenClose.svg";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/TableIcons/calendar.svg";
//data and utils

const TransactionsSearchBar = () => {
  //searchQuery
  const [searchTerm, setSearchTerm] = useState("");
  //filter transactions functionality
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.filter);
  const filterParameters = useSelector((state) => state.filterParameters);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleToggleFilter = () => {
    dispatch(toggleFilter());
  };

  //DATE RANGE

  const DateFilterInput = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-dropdown" ref={ref} onClick={onClick}>
      <span style={{ position: "absolute", left: "0", marginLeft: "16px" }}>
        <CalenderIcon />
      </span>
      <span>
        {endDate ? (
          <span style={{ fontSize: "10px" }}>{`${moment(startDate).format(
            "DD/MM/YY"
          )} - ${moment(endDate).format("DD/MM/YY")}`}</span>
        ) : (
          "Filter by date"
        )}
      </span>
    </div>
  ));

  const CalendarComponent = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              color: "#4F7C06",
              fontSize: "20px",
              fontFamily: "DM Sans",
            }}
          >
            Select Date Range
          </h3>{" "}
        </div>

        <br />
        <div style={{ marginBottom: "12px" }}>
          <input
            type="text"
            className="calendar-inputs"
            readOnly
            value={
              filterParameters.dateRange.startDate &&
              filterParameters.dateRange.startDate
            }
          />
          <>-</>
          <input
            type="text"
            className="calendar-inputs"
            style={{ marginLeft: "8px" }}
            readOnly
            // value={endDate && moment(endDate).format("DD-MM-YYYY")}
            value={
              filterParameters.dateRange.endDate &&
              filterParameters.dateRange.endDate
            }
          />
        </div>
        <div
          style={{
            position: "relative",
            backgroundColor: "#FFFAF6",
            padding: "5px",
          }}
        >
          {children}
        </div>
      </CalendarContainer>
    );
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    dispatch(addStartDate(moment(start).format("YYYY-MM-DD")));
    end
      ? dispatch(addEndDate(moment(end).format("YYYY-MM-DD")))
      : dispatch(addEndDate(""));
    console.log(dates);
  };

  const clearDates = () => {
    // setStart("");

    setStartDate("");
    setEndDate("");
    dispatch(clearDateRange());
    // setEnd("");
    // setQueryDate(!queryDate);
    // console.log("Dates cleared");
  };

  useEffect(() => {
    dispatch(setSearchQuery(searchTerm));
  }, [searchTerm]);

  return (
    <div className="search-bar-container">
      <Input
        placeholder={"Search by name or account no."}
        logo={<SearchIcon />}
        value={searchTerm}
        // handleInputChange={(e) => dispatch(setSearchQuery(e.target.value))}
        handleInputChange={(e) => setSearchTerm(e.target.value)}
      />

      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "24px" }}>
          <ReactDatePicker
            startDate={startDate}
            endDate={endDate}
            customInput={<DateFilterInput />}
            calendarContainer={CalendarComponent}
            calendarClassName="calendar"
            selectsRange={true}
            onChange={onChange}
          >
            <div
              style={{
                color: "#4F7C06",
                textAlign: "end",
                fontFamily: "DM Sans",
                fontWeight: "700",
                cursor: "default",
              }}
              onClick={() => clearDates()}
            >
              Clear
            </div>
          </ReactDatePicker>
        </div>

        <Input
          value="Filter by"
          logo={<FilterIcon />}
          logo_2={<ArrowRightIcon />}
          inputStyle={{ width: "168px" }}
          disabled={true}
          // handleInputClick={() => console.log(filterParameters)}
          handleInputClick={handleToggleFilter}
        ></Input>

        <DownloadTransactionBtn
          condition={filterParameters.dateRange.endDate}
        />
      </div>
    </div>
  );
};

export default TransactionsSearchBar;
