import React from "react";
import { ButtonGreenStyle, ButtonWhiteStyle } from "./ButtonStyle";
import "./Button.css";

export const Button = ({ name, className, handleClick, disabled, style }) => {
  return (
    <button
      className={className}
      type="button"
      onClick={handleClick}
      disabled={disabled}
      style={style}
    >
      {name}
    </button>
  );
};

export const ButtonGreenFilled = ({ icon, name, handleClick, style }) => {
  return (
    <ButtonGreenStyle onClick={handleClick} style={style}>
      <div>{icon}</div>
      <div>{name}</div>
    </ButtonGreenStyle>
  );
};
export const ButtonWhiteOutline = ({ icon, name, handleClick, style }) => {
  return (
    <ButtonWhiteStyle onClick={handleClick} style={style}>
      <div>{icon}</div>
      <div>{name}</div>
    </ButtonWhiteStyle>
  );
};
