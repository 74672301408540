import React, { useState, useEffect, useRef, forwardRef } from "react";

//dependencies
import moment from "moment";
import { Tooltip } from "react-tooltip";

//svg components
import { ReactComponent as KebabIcon } from "../../assets/icons/TableIcons/kebab.svg";
import { ReactComponent as SuccessfulBadge } from "../../assets/icons/TableIcons/successfulBadge.svg";
import { ReactComponent as PendingBadge } from "../../assets/icons/TableIcons/pendingBadge.svg";
import CreditDebitCarret from "../../components/CreditDebitCarret";

//components
import Avatar from "../../components/avatar/Avatar";
import InstitutionTransactionCard from "../../components/cardsAndModals/customTableCard/InstitutionTransactionCard";

const useGetTransactionsColumns = () => {
  //local variables and functions
  const ref = useRef();
  //   const location = useLocation();
  const [showCard, setShowCard] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const transactionTableColumns = [
    {
      name: "ACCOUNT NAME",
      selector: (row) => {
        return (
          <div className="name-container">
            <Avatar name={row.name} size={35} round />
            <div className="name-content">
              <div
                className="content-line-1"
                data-tooltip-id="account-name"
                data-tooltip-content={row.name}
              >
                <>{row.name}</>
                <Tooltip id="account-name" />
              </div>
              <div className="content-line-2">{row.institutionName}</div>
            </div>
          </div>
        );
      },
      grow: 2,
    },
    {
      name: "ACCOUNT NO.",
      selector: (row) => row.accountNumber,
    },
    {
      name: " AMOUNT",
      selector: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CreditDebitCarret transactionType={row.transactionType} />
            <div
              style={{
                color: `${
                  row.transactionType === "credit" ? "#03874A" : "#C21306"
                }`,
              }}
            >
              {row.transactionType === "credit" ? "+" : "-"}
              {row.amount}
            </div>
          </div>
        );
      },
      grow: 1.5,
    },
    {
      name: "STATUS",
      selector: (row) => {
        if (row.transactionStatus === "SUCCESSFUL") {
          return <SuccessfulBadge />;
        }
        if (row.transactionStatus === "PENDING") {
          return <PendingBadge />;
        }
      },
    },
    {
      name: "CHANNELS",
      selector: (row) => {
        return (
          <div>
            <div>{row.transactionService}</div>
            <div
              style={{
                color: "#7A7A7A",
                fontSize: "13px",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: "0.5px",
                textTransform: "capitalize",
              }}
            >
              {row.transactionServiceCategory}
            </div>
          </div>
        );
      },

      grow: 2,
    },
    {
      name: "OLD BALANCE",
      selector: (row) => (
        <div
          data-tooltip-id="old-balance"
          data-tooltip-content={row.oldBalance}
        >
          <>{row.oldBalance}</>
          <Tooltip id="old-balance" />
        </div>
      ),

      // grow: 0.5,
    },
    {
      name: "NEW BALANCE",
      selector: (row) => (
        <div
          data-tooltip-id="new-balance"
          data-tooltip-content={row.newBalance}
        >
          <>{row.newBalance}</>
          <Tooltip id="new-balance" />
        </div>
      ),

      // grow: 0.5,
    },
    {
      name: "DATE",
      selector: (row) => {
        return (
          <div>
            <div>{moment(row.createdAt).format("DD/MM/YY")}</div>
            <div
              style={{
                color: "#7A7A7A",
                fontSize: "13px",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: "0.5px",
                textTransform: "capitalize",
                textAlign: "end",
              }}
            >
              {moment(row.createdAt).format(`hh:mma`)}
            </div>
          </div>
        );
      },
    },

    {
      name: "",
      cell: (row, index, column, id) =>
        showCard === index ? (
          <InstitutionTransactionCard
            refVar={ref}
            showContact={showContact}
            setShowContact={setShowContact}
            showCard={showCard}
            setShowCard={setShowCard}
            row={row}
          />
        ) : (
          <KebabIcon onClick={() => setShowCard(index)} />
        ),

      center: 1,
      width: "70px",
    },
  ];

  //Check outsideclick
  useEffect(() => {
    const checkOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowCard(null);
        setShowContact(false);
      }
    };
    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [showCard]);

  return {
    transactionTableColumns,
    showCard,
    setShowCard,
    showContact,
    setShowContact,
  };
};

export default useGetTransactionsColumns;
