import styled from "styled-components";

const BreadcrumbStyle = styled.ul`
  list-style: none;
  display: flex;
  color: #203202;
  font-size: 14px;
  font-family: "DM Sans";
  letter-spacing: 0.16px;
  text-transform: capitalize;

  li {
    margin-left: 5px;
  }
`;

export default BreadcrumbStyle;
