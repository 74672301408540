import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";

import BreadcrumbStyle from "./BreadcrumbStyle";

function Breadcrumb({ institutionId }) {
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.split("/").filter((path) => path !== "");

  return (
    <BreadcrumbStyle>
      {/* <li>
					<Link to='/'>Home</Link>
				</li> */}
      {paths.map((path, index, array) => (
        <li
          key={index}
          style={{
            borderBottom: index === array.length - 1 ? "none" : "1px solid ",
            color: index === array.length - 1 ? "#434C61" : "inherit",
            fontWeight: index === array.length - 1 ? "inherit" : "500",
          }}
          onClick={() => {
            console.log(location.pathname);

            navigate(`/${paths.slice(0, index + 1).join("/")}`, {
              state: {
                data: {
                  institutionId: institutionId,
                },
              },
            });
          }}
        >
          &nbsp;{index === 0 ? "" : "/"} {decodeURI(path)}
        </li>
      ))}
    </BreadcrumbStyle>
  );
}

export default Breadcrumb;
