import styled from "styled-components";

const StatsCardStyle = styled.div`
  min-width: 300px;
  height: 165px;
  background: #fbfbfb;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-left: 23px;
  padding-right: 23px;
  font-family: "DM Sans";

  h3 {
    margin-top: 24px;
    margin-bottom: 51.5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .card-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    display: flex;
  }

  span {
    font-weight: 400;
    font-size: 40px;
    letter-spacing: 0.119311px;
    margin-right: 5px;
  }
`;

export { StatsCardStyle };
