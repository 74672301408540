import React, { useState, useEffect, useRef, forwardRef } from "react";

//dependencies
import moment from "moment";
import { Tooltip } from "react-tooltip";

//svg components
import { ReactComponent as KebabIcon } from "../../assets/icons/TableIcons/kebab.svg";

//components
import Avatar from "../../components/avatar/Avatar";
import AccountsTableCard from "../../components/cardsAndModals/customTableCard/AccountsTableCard";

const useGetAccountsColumns = () => {
  //local variables and functions
  const ref = useRef();

  const [showCard, setShowCard] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const accountsTableColumns = [
    {
      name: "NAME & INSTITUTION",
      selector: (row) => {
        return (
          <div className="name-container">
            <Avatar name={row.accountName} size={35} round />
            <div className="name-content">
              <div
                className="content-line-1"
                data-tooltip-id="account-name"
                data-tooltip-content={row.accountName}
              >
                <>{row.accountName}</>
                <Tooltip id="account-name" />
              </div>
              <div className="content-line-2">{row.institutionName}</div>
            </div>
          </div>
        );
      },
      grow: 3,
    },
    {
      name: "STATE AND REGION",
      selector: (row) => {
        return (
          <div>
            <div>{row.state}</div>
            <div
              style={{
                color: "#7A7A7A",
                fontSize: "13px",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: ".5008px",
                textTransform: "capitalize",
              }}
            >
              {row.lga}
            </div>
          </div>
        );
      },
      grow: 1.5,
    },

    {
      name: "ORGANIZATION ID",
      selector: (row) => row.organizationId,
      grow: 1.5,
    },
    {
      name: "A/C TIER",
      selector: (row) => row.kyc,
      grow: 0.5,
    },
    {
      name: "DAILY A/C LIMIT",
      selector: (row) => row.dailyTransactionLimit,
      grow: 1.5,
    },
    {
      name: "A/C NO. & BANK NAME",
      selector: (row) => {
        return (
          <div>
            <div>{row.accountNumber}</div>
            <div>Tanadi MFB</div>
          </div>
        );
      },
      grow: 1.5,
    },
    {
      name: "PHONE NUMBER",
      selector: (row) => (
        <div
          data-tooltip-id="phone-number"
          data-tooltip-content={row.phoneNumber}
        >
          <>{row.phoneNumber}</>
          <Tooltip id="phone-number" />
        </div>
      ),
      grow: 1.5,
    },
    {
      name: "",
      cell: (row, index, column, id) =>
        showCard === index ? (
          <AccountsTableCard
            row={row}
            refVar={ref}
            showContact={showContact}
            setShowContact={setShowContact}
            showCard={showCard}
            setShowCard={setShowCard}
          />
        ) : (
          <KebabIcon onClick={() => setShowCard(index)} />
        ),
      // allowOverflow: true,
      grow: 0.5,
      center: 1,
    },
  ];

  //Check outsideclick
  useEffect(() => {
    const checkOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowCard(null);
        setShowContact(false);
      }
    };
    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [showCard]);

  return {
    accountsTableColumns,
    showCard,
    setShowCard,
    showContact,
    setShowContact,
  };
};

export default useGetAccountsColumns;
