export const institutionTableCustomStyles = {
  responsiveWrapper: {
    style: {
      color: "red",
      overflow: "visible",
    },
  },

  table: {
    style: {
      border: "1px solid #EFF1F4",
      position: "relative",
    },
  },
  rows: {
    style: {
      minHeight: "72px", // override the row height
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: "400",
      color: "#2B3241",
      borderBottom: "1px solid #DBDFE7 !important",
    },

    // highlightOnHoverStyle: {
    // 	backgroundColor: "rgba(255, 250, 246, 0.5)",
    // 	borderBottomColor: "#D3D3D3",
    // 	outlineColor: "#D3D3D3",
    // },
  },
  headCells: {
    style: {
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "25px",
      backgroundColor: "#FDFFEA",
      fontFamily: "DM Sans",
      fontSize: "12px",
      fontWeight: "500",
      color: "#2B3241",
    },
  },
  cells: {
    style: {
      paddingLeft: "25px", // override the cell padding for data cells
      paddingRight: "25px",
      minWidth: "100px",
    },
  },
};

export const institutionDetailStyles = {
  table: {
    style: {
      border: "1px solid #EFF1F4",
      position: "relative",
      paddingBottom: "100px",
    },
  },
  rows: {
    style: {
      minHeight: "72px", // override the row height
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: "400",
      color: "#2B3241",
      borderBottom: "1px solid #DBDFE7 !important",
    },

    // highlightOnHoverStyle: {
    // 	backgroundColor: "rgba(255, 250, 246, 0.5)",
    // 	borderBottomColor: "#D3D3D3",
    // 	outlineColor: "#D3D3D3",
    // },
  },
  headCells: {
    style: {
      paddingLeft: "25px", // override the cell padding for head cells
      paddingRight: "25px",
      backgroundColor: "#FDFFEA",
      fontFamily: "DM Sans",
      fontSize: "12px",
      fontWeight: "500",
      color: "#2B3241",
    },
  },
  cells: {
    style: {
      paddingLeft: "25px", // override the cell padding for data cells
      paddingRight: "25px",
      minWidth: "100px",
    },
  },
};

export const institutionTransactionStyles = {
  table: {
    style: {
      border: "1px solid #EFF1F4",
      position: "relative",
    },
  },
  rows: {
    style: {
      minHeight: "72px", // override the row height
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: "400",
      color: "#2B3241",
      borderBottom: "1px solid #DBDFE7 !important",
    },

    // highlightOnHoverStyle: {
    // 	backgroundColor: "rgba(255, 250, 246, 0.5)",
    // 	borderBottomColor: "#D3D3D3",
    // 	outlineColor: "#D3D3D3",
    // },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "10px",
      backgroundColor: "#FDFFEA",
      fontFamily: "DM Sans",
      fontSize: "12px",
      fontWeight: "500",
      color: "#2B3241",
    },
  },
  cells: {
    style: {
      paddingLeft: "10px", // override the cell padding for data cells
      paddingRight: "10px",
      minWidth: "100px",
    },
  },
};
