//components
import Institutions from "../pages/institutionsPage/Institutions";
import InstitutionAccounts from "../pages/institutionsPage/InstitutionAccounts";
import InstitutionTransactions from "../pages/institutionsPage/InstitutionTransactions";
import InstitutionDetails from "../pages/institutionsPage/InstitutionDetails";

//dependencies
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";

const InstitutionRoutes = () => {
  return (
    <Routes>
      <Route index element={<Institutions />} />

      <Route path=":institutionTransaction" element={<InstitutionDetails />} />
    </Routes>
  );
};

export default InstitutionRoutes;
