import React, { useEffect, useRef } from "react";

//dependencies
import { useNavigate } from "react-router-dom";
import moment from "moment";

//redux
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../../redux/slices/modalSlice";
import { setReceipt } from "../../../redux/slices/receiptSlice";
//styled components
import { CustomCardStyle3 } from "./CardStyle";
//components
import CardListItem from "./CardListItem";
//svg components
import { ReactComponent as DownloadIcon } from "../../../assets/icons/cardIcons/downloadIcon.svg";

//data and utils
import { formatText } from "../../../utils/utils";

const InstitutionTransactionCard = ({ refVar, setShowCard, row }) => {
  const dispatch = useDispatch();

  const handleToggleModal = () => {
    setShowCard(false);
    dispatch(toggleModal());
  };

  const handleReceiptDetails = () => {
    const receiptData = {
      institutionId: row.transactionId,
      recptAccountName: row.recipientAccountName,
      recptAccountNo: row.recipientAccountNumber,
      recptBankName: "Tanadi MFB",
      senderAccountName: row.accountName,
      senderAccountNo: row.accountNumber,
      senderBankName: row.serviceProvider,
      amount: row.amount,
      status: row.transactionStatus,
      channel: `${formatText(row.transactionService)} - ${formatText(
        row.transactionServiceCategory
      )}`,
      narration: row.narration,
      referenceNo: row.cbaTransactionRef,
      transactionType: row.transactionType,
      sessionId: row.platformTransactionRef,
      dataAndTime:
        moment(row.createdAt).format("DD/MM/YY") +
        " - " +
        moment(row.createdAt).format(`hh:mma`),
      oldBalance: row.oldBalance,
      newBalance: row.newBalance,
    };
    dispatch(setReceipt(receiptData));
  };

  const handleDownloadClick = () => {
    handleToggleModal();
    handleReceiptDetails();
  };
  return (
    <CustomCardStyle3 ref={refVar}>
      <li className="kebab-list-item" onClick={handleDownloadClick}>
        <DownloadIcon style={{ marginRight: "18px", cursor: "default" }} />
        Download transaction receipt
      </li>
    </CustomCardStyle3>
  );
};

export default InstitutionTransactionCard;
