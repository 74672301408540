import styled from "styled-components";

const AccountProfile = styled.div`
	font-family: "DM Sans";
	background-color: #fff;
	position: absolute;
	right: 24px;
	top: 85px;
	width: 383px;
	border-radius: 8px;
	border: 0.5px solid var(--grayscale-100, #DBDFE7);
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
	z-index: 10;

	

.profile-details-wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 24px;
		border-bottom: 0.3px solid var(--grayscale-100, #DBDFE7);

	.profile-name-email{
		
		h3{
			font-size: 20px;
			font-weight: 500;
			/* line-height: 16px;  */
		}

		p{
			color: var(--grayscale-200, #98A2B3);
			font-size: 14px;
			font-weight: 400;
		}
	}
	.role-tag{
		width: fit-content;
		padding: 2px;
		border-radius: 4px;
		border: 0.5px solid var(--primary-100, #81A449);
		background: var(--primary-25, #ECF9D8);
		font-size: 12px;
	
	}
	}

	.logout-wrapper{
		display: flex;
		justify-content: space-between;
		color: var(--grayscale-300, #667085);
		font-size: 16px;
		font-weight: 400;
		line-height: 24px; /* 150% */
		padding: 24px;
	}
	.logout-wrapper:hover{
			background-color: #ECF9D8;
			color: #000;
			
			svg {
				path{
					stroke: #000;
				}
			}
		}
`;

export { AccountProfile };
