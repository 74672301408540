import React, { useState, useEffect, useRef } from "react";

//dependencies
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation, Link } from "react-router-dom";

//custom hooks
import useGetTransactionsApi from "../../customHooks/Transactions/useGetTransactionsApi";
import useGetTransactionsColumns from "../../customHooks/Transactions/useGetTransactionColumns";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRowsPerPage,
  setCurrentPage,
} from "../../redux/slices/paginationSlice";
import { clearFilterParameters } from "../../redux/slices/filerParametersSlice";
import { setSearchQuery } from "../../redux/slices/searchQuerySlice";

//styled components
import { TableDetailStyle } from "../TableStyle";
//components
import TransactionsSearchBar from "../../components/tableComponents/transactionsSearchBar/TransactionsSearchBar";
import NavbarSidemenuHOC from "../../HOC/NavbarSidemenuHOC";
import Avatar from "../../components/avatar/Avatar";
import InstitutionTransactionCard from "../../components/cardsAndModals/customTableCard/InstitutionTransactionCard";
import TransactionReceipts from "../../components/transactionReceipts/TransactionReceipts";
import CreditDebitCarret from "../../components/CreditDebitCarret";
import { Modal } from "../../modals/Modal";
import TransactionFilter from "../../components/tableComponents/transactionFilterComponent/TransactionFilter";

//svg components

//data and utils
import { paginationOptions } from "../../data/data";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { institutionTransactionStyles } from "../../data/tableStyles";

import "../table.css";

const InstitutionTransactions = () => {
  //local variables and functions
  const ref = useRef();

  const { institutionTransaction } = useParams();
  // const param = useParams();
  const location = useLocation();
  const { institutionId } = location.state.data;

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeRowsPerPage = (rowsPerPageValue) => {
    dispatch(setRowsPerPage(rowsPerPageValue));
    dispatch(setCurrentPage(1));
  };

  // redux dispatch and selectors
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.filter);
  const paginationParams = useSelector((state) => state.pagination);

  //fetchTransactions
  const id = institutionId;
  const acctNo = null;
  const { transactionList, loading, totalTransactions, pageCount } =
    useGetTransactionsApi(id, acctNo);

  //fetch table column
  const {
    transactionTableColumns,
    showCard,
    setShowCard,
    showContact,
    setShowContact,
  } = useGetTransactionsColumns();

  //Check outsideclick
  useEffect(() => {
    console.log(institutionTransaction);
    const checkOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowCard(null);
        setShowContact(false);
      }
    };
    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [showCard]);

  //clear pagination and filter
  useEffect(() => {
    dispatch(clearFilterParameters());
    dispatch(setSearchQuery(""));

    return () => {
      dispatch(clearFilterParameters());
      dispatch(setSearchQuery(""));
    };
  }, []);

  return (
    <>
      <Modal>
        <TransactionReceipts />
      </Modal>

      {/* toggle display filter */}
      {showFilter && <TransactionFilter />}
      <TableDetailStyle>
        <div className="back-button">
          <Breadcrumb institutionId={institutionId} />
        </div>
        <div className="detail-summary-container">
          <div>
            <p>{totalTransactions} transactions</p>
          </div>

          <div className="institutionId-wrapper">
            <p>InstitutionId</p>
            <h4>{institutionId}</h4>
          </div>
        </div>

        <TransactionsSearchBar />

        <DataTable
          columns={transactionTableColumns}
          data={transactionList}
          customStyles={institutionTransactionStyles}
          responsive
          noTableStyles
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={pageCount * paginationParams.rowsPerPage}
          paginationPerPage={paginationParams.rowsPerPage}
          paginationRowsPerPageOptions={[50]}
          paginationComponentOptions={paginationOptions}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableDetailStyle>
    </>
  );
};

export default NavbarSidemenuHOC(InstitutionTransactions);
