import React, { useState, useRef, useEffect } from "react";

//dependencies
import DataTable from "react-data-table-component";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRowsPerPage,
  setCurrentPage,
} from "../../redux/slices/paginationSlice";
import { setSearchQuery } from "../../redux/slices/searchQuerySlice";

//custom hooks
import useGetAccountsApi from "../../customHooks/Accounts/useGetAccountsApi";
import useGetAccountsColumns from "../../customHooks/Accounts/useGetAccountsColumns";

//styled components
import { TableStyle } from "../TableStyle";

//components
import NavbarSideMenuHOC from "../../HOC/NavbarSidemenuHOC";
import Input from "../../components/input/Input";
import TableWrapper from "../../components/TableWrapper";

//svg components
import { ReactComponent as SearchIcon } from "../../assets/icons/TableIcons/searchIcon.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/TableIcons/filter.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/blackArrowForward.svg";

//data and utils
import { paginationOptions } from "../../data/data";
import { institutionTableCustomStyles } from "../../data/tableStyles";

const Accounts = () => {
  //redux dispatch and selectors
  const dispatch = useDispatch();
  const paginationParams = useSelector((state) => state.pagination);

  //fetch table column
  const { accountsTableColumns, showCard, setShowCard, setShowContact } =
    useGetAccountsColumns();

  const { totalAccounts, customerList, pageCount, loading } =
    useGetAccountsApi();

  //searchQuery
  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeRowsPerPage = (rowsPerPageValue) => {
    dispatch(setRowsPerPage(rowsPerPageValue));
    dispatch(setCurrentPage(1));
  };

  useEffect(() => {
    dispatch(setSearchQuery(searchTerm));
  }, [searchTerm]);

  return (
    <TableStyle>
      <div className="table-title">
        <h1>All Accounts</h1>
        <p>{totalAccounts} total accounts</p>
      </div>

      <div className="search-bar-container">
        <Input
          placeholder={"Search by name, account no. or organization ID"}
          logo={<SearchIcon />}
          value={searchTerm}
          handleInputChange={(e) => setSearchTerm(e.target.value)}
        />

        <div style={{ display: "flex", gap: "24px" }}>
          <Input
            value="Filter by"
            logo={<FilterIcon />}
            logo_2={<ArrowRightIcon />}
            inputStyle={{ width: "168px" }}
            disabled={true}
          ></Input>
        </div>
      </div>

      <TableWrapper>
        <DataTable
          columns={accountsTableColumns}
          data={customerList}
          customStyles={institutionTableCustomStyles}
          responsive
          noTableStyles
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={pageCount * paginationParams.rowsPerPage}
          paginationPerPage={paginationParams.rowsPerPage}
          paginationRowsPerPageOptions={[50]}
          paginationComponentOptions={paginationOptions}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableWrapper>
    </TableStyle>
  );
};

export default NavbarSideMenuHOC(Accounts);
