import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: false,
  reducers: {
    toggleModal: (state) => !state,
  },
});

//this is for dispatch
export const { toggleModal } = modalSlice.actions;

//this is for reducer
export default modalSlice.reducer;
