import React, { useEffect, useState } from "react";
import {
  TotalListContainer,
  CustomTableWrapper,
} from "./AccountListTotalStyles";

import { http } from "../../../axios-https";
import env from "../../../env";

import DataTable from "react-data-table-component";

const AccountListTotal = () => {
  const [institutionSummary, setInstitutionSummary] = useState([]);

  const getInstitutionSummary = () => {
    http
      .get(`${env.greenbanker_admin_base_url}/institutions-summary`)
      .then((response) => {
        // console.log(response.data.data.content);
        const listItem = response.data.data.content.map((item) => {
          return {
            institutionName: item.name,
            noOfAccounts: item.totalFarmers,
          };
        });

        setInstitutionSummary([...listItem]);
      })
      .catch((error) => console.log(error));
  };

  const columns = [
    {
      name: "Institution name",
      selector: (row) => row.institutionName,
    },
    {
      name: "No. of accounts",
      selector: (row) => row.noOfAccounts,
      right: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "",
    rangeSeparatorText: "of",
    // selectAllRowsItem: true,
    // selectAllRowsItemText: "Todos",
  };
  const customStyles = {
    rows: {
      style: {
        minHeight: "48px", // override the row height
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: "400",
        color: "#000",
      },

      // highlightOnHoverStyle: {
      // 	backgroundColor: "rgba(255, 250, 246, 0.5)",
      // 	borderBottomColor: "#D3D3D3",
      // 	outlineColor: "#D3D3D3",
      // },
    },
    headCells: {
      style: {
        paddingLeft: "16px", // override the cell padding for head cells
        paddingRight: "16px",
        backgroundColor: "#ECF9D8",
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: "500",
        color: "#2F4A04",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "16px",
        minWidth: "100px",
      },
    },
  };

  useEffect(() => {
    getInstitutionSummary();
  }, []);

  return (
    <TotalListContainer>
      <h4>Total Accounts by Institutions </h4>
      <CustomTableWrapper>
        <DataTable
          columns={columns}
          data={institutionSummary}
          customStyles={customStyles}
          // responsive={true}
          fixedHeader={true}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationPerPage={7}
          paginationRowsPerPageOptions={[7]}
        />
      </CustomTableWrapper>
    </TotalListContainer>
  );
};

export default AccountListTotal;
