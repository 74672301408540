import { ReactComponent as CreditCarret } from "../assets/icons/TableIcons/greenCarretUp.svg";
import { ReactComponent as DebitCarret } from "../assets/icons/TableIcons/redCarretDown.svg";

import React from "react";

const CreditDebitCarret = ({ transactionType }) => {
  return (
    <>{transactionType === "credit" ? <CreditCarret /> : <DebitCarret />}</>
  );
};

export default CreditDebitCarret;
