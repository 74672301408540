import React, { useState, useEffect, useRef, forwardRef } from "react";

//dependencies
import env from "../../env";
import { http } from "../../axios-https";
import { useSelector, useDispatch } from "react-redux";

import { formatText, formatCurrency, buildQuery } from "../../utils/utils";

const useGetAccountsApi = (id, acctNo) => {
  const [totalAccounts, setTotalAccounts] = useState("Fetching");
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);

  const searchQuery = useSelector((state) => state.searchQuery);
  const paginationParams = useSelector((state) => state.pagination);

  const getCustomers = () => {
    const accountsQuery = {
      size: paginationParams.rowsPerPage,
      page: paginationParams.currentPage,
      filterNameOrAccountNoOrOrganizationId: searchQuery || acctNo,
      filterInstitutionId: id,
    };

    setLoading(true);
    http
      .get(
        `${env.greenbanker_admin_base_url}/customers?${buildQuery(
          accountsQuery
        )}`
      )
      .then((response) => {
        // console.log(response.data.data.content);
        const listItem = response.data.data.content.map((item) => {
          return {
            userId: item.userId,
            accountName: formatText(`${item.firstName} ${item.lastName}`),
            phoneNumber: item.phone,
            gender: item.gender,
            dateOfBirth: item.dateOfBirth,
            timeCreated: item.timeCreated,
            organizationId: item.organizationId,
            ward: item.ward,
            kyc: item.kyc,
            dailyTransactionLimit: formatCurrency(item.dailyTransactionLimit),
            lga: item?.lga?.toLowerCase(),
            state: item.state,
            accountNumber: item.accountNumber,
            accountType: item.accountType,
            dailyLimit: item.dailyWithdrawalLimit,
            assignedTo: item.assignedTo,
            institutionName: item.institutionName,
            institutionId: item.institutionId,
          };
        });

        setPageCount(
          Math.ceil(
            response.data.data.totalElements / paginationParams.rowsPerPage
          )
        );
        setTotalAccounts(response.data.data.totalElements);

        setCustomerList([...listItem]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    //debounce
    setTimeout(() => {
      getCustomers();
    }, 1000);
  }, [searchQuery, paginationParams]);

  return { loading, totalAccounts, customerList, pageCount };
};

export default useGetAccountsApi;
