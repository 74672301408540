import React from "react";
import { ReactComponent as CrustAdminLogo } from "../../assets/icons/sidebarMenuIcons/crustCore.svg";

import SidebarMenuLogo from "./SidebarMenuLogo";
import {
	SidebarMenuContainer,
	SidebarMenuTitle,
	SidebarMenuItem,
	SidebarMenuList,
} from "./SidebarMenuStyles";
import { NavLink } from "react-router-dom";

import { sidebarMenuItems } from "../../data/data";

const SidebarMenu = () => {
	return (
		<SidebarMenuContainer>
			<SidebarMenuTitle>
				<CrustAdminLogo style={{ marginRight: "8px" }} />
				Crust Core
			</SidebarMenuTitle>
			<SidebarMenuList>
				{sidebarMenuItems.map((menuItem, index) => {
					return (
						<NavLink
							key={index}
							to={`/${menuItem.toLowerCase()}`}
							className={(navData) => (navData.isActive ? "active" : "")}
						>
							<SidebarMenuItem>
								<SidebarMenuLogo logoName={menuItem} />
								{menuItem}
							</SidebarMenuItem>
						</NavLink>
					);
				})}
			</SidebarMenuList>
		</SidebarMenuContainer>
	);
};

export default SidebarMenu;
