import React, { useState } from "react";

import TabSelector from "../../TabSelector";
import {
	AccountOverview,
	SelectedTab,
	UnselectedTab,
} from "./AccountOverviewStyles";

import ActiveAccounts from "./ActiveAccounts";
import BvnInfo from "./BvnInfo";
import TransactionCount from "./TransactionCount";

const RenderInfo = ({ totalFarmers, withBvn, selection }) => {
	if (selection === "Active accounts") {
		return <ActiveAccounts totalFarmers={totalFarmers} />;
	}
	if (selection === "BVN info") {
		return <BvnInfo totalFarmers={totalFarmers} withBvn={withBvn} />;
	}
	if (selection === "Transaction count") {
		return <TransactionCount />;
	}
};

const AccountOverviewContainer = ({ totalFarmers, withBvn }) => {
	const [selectedTab, setSelectedTab] = useState("Active accounts");
	return (
		<AccountOverview>
			<div className='accounts-overview-title'>
				<h4>Overview of Accounts</h4>
			</div>
			<TabSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
			{/* Account overview content */}
			{/* {RenderInfo(selectedTab)} */}
			<RenderInfo
				selection={selectedTab}
				totalFarmers={totalFarmers}
				withBvn={withBvn}
			/>
		</AccountOverview>
	);
};

export default AccountOverviewContainer;
