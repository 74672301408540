import React, { useState, useRef, useEffect } from "react";

//dependencies
import { Outlet, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRowsPerPage,
  setCurrentPage,
  resetCurrentPage,
} from "../../redux/slices/paginationSlice";

import { setSearchQuery } from "../../redux/slices/searchQuerySlice";

//styled components
import { TableDetailStyle } from "../TableStyle";

//custom hooks
import useGetAccountsApi from "../../customHooks/Accounts/useGetAccountsApi";
import useGetAccountsColumns from "../../customHooks/Accounts/useGetAccountsColumns";

//components
import TestHOC from "../../HOC/NavbarSidemenuHOC";
import Input from "../../components/input/Input";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

//svg components
import { ReactComponent as SearchIcon } from "../../assets/icons/TableIcons/searchIcon.svg";

//data
import { formatText } from "../../utils/utils";
import { paginationOptions } from "../../data/data";
import { institutionDetailStyles } from "../../data/tableStyles";

const InstitutionAccounts = () => {
  //redux dispatch and selectors
  const dispatch = useDispatch();
  const paginationParams = useSelector((state) => state.pagination);
  const location = useLocation();

  const { institutionDetail } = useParams();

  const { institutionId, institutionName } = location.state.data;

  //move to redux eventually
  const ref = useRef();
  // const [showCard, setShowCard] = useState(false);
  // const [showContact, setShowContact] = useState(false);
  const [accountList, setAccountList] = useState([]);
  // const [totalAccounts, setTotalAccounts] = useState("Fetching");

  //searchQuery
  const [searchTerm, setSearchTerm] = useState("");

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeRowsPerPage = (rowsPerPageValue) => {
    dispatch(setRowsPerPage(rowsPerPageValue));
    dispatch(setCurrentPage(1));
  };

  // const [loading, setLoading] = useState(true);

  //fetchTransactions
  const id = institutionId;
  const acctNo = null;
  const { loading, totalAccounts, customerList, pageCount } = useGetAccountsApi(
    id,
    acctNo
  );

  //fetch table column
  const {
    accountsTableColumns,
    showCard,
    setShowCard,
    showContact,
    setShowContact,
  } = useGetAccountsColumns();

  // clear pagination and filter
  useEffect(() => {
    dispatch(resetCurrentPage());
  }, []);

  useEffect(() => {
    dispatch(setSearchQuery(searchTerm));
  }, [searchTerm]);

  return (
    <>
      <TableDetailStyle>
        <div className="back-button">
          <Breadcrumb institutionId={institutionId} />
        </div>
        <div className="detail-summary-container">
          <div>
            <h1>{institutionDetail}</h1>
            <p>{totalAccounts} Customer accounts</p>
          </div>

          <div className="institutionId-wrapper">
            <p>InstitutionId</p>
            <h4>{institutionId}</h4>
          </div>
        </div>

        <div className="search-bar-container">
          <Input
            placeholder={"Search by name, account no."}
            logo={<SearchIcon />}
            value={searchTerm}
            handleInputChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <DataTable
          columns={accountsTableColumns}
          data={customerList}
          customStyles={institutionDetailStyles}
          responsive
          noTableStyles
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={pageCount * paginationParams.rowsPerPage}
          paginationPerPage={paginationParams.rowsPerPage}
          paginationRowsPerPageOptions={[50]}
          paginationComponentOptions={paginationOptions}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableDetailStyle>
      {/* <Outlet /> */}
    </>
  );
};

export default TestHOC(InstitutionAccounts);
