import React, { useState, useEffect, useRef, forwardRef } from "react";

//dependencies
import moment from "moment";
import { Tooltip } from "react-tooltip";

//svg components
import { ReactComponent as KebabIcon } from "../../assets/icons/TableIcons/kebab.svg";
import { ReactComponent as SuccessfulBadge } from "../../assets/icons/TableIcons/successfulBadge.svg";
import { ReactComponent as PendingBadge } from "../../assets/icons/TableIcons/pendingBadge.svg";
import CreditDebitCarret from "../../components/CreditDebitCarret";

//components
import Avatar from "../../components/avatar/Avatar";
import InstitutionTableCard from "../../components/cardsAndModals/customTableCard/InstitutionTableCard";

const useGetInstitutionColumns = () => {
  //local variables and functions
  const ref = useRef();
  //   const location = useLocation();
  const [showCard, setShowCard] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const institutionTableColumns = [
    {
      name: "NAME & EMAIL",
      selector: (row) => {
        return (
          <div className="name-container">
            <Avatar name={row.institutionName} size={35} round />
            <div className="name-content">
              <div
                className="content-line-1"
                data-tooltip-id="institution-name"
                data-tooltip-content={row.institutionName}
              >
                <>{row.institutionName}</> <Tooltip id="institution-name" />
              </div>
              <div className="content-line-2">{row.institutionEmail}</div>
            </div>
          </div>
        );
      },
      grow: 3,
    },
    {
      name: "ADDRESS",
      selector: (row) => (
        <div data-tooltip-id="address" data-tooltip-content={row.address}>
          <>{row.address}</> <Tooltip id="address" />
        </div>
      ),
      grow: 3,
    },

    {
      name: "CUSTOMERS A/Cs",
      selector: (row) => row.totalUsers,
      right: true,
      grow: 2,
    },
    {
      name: "SUB A/Cs",
      selector: (row) => row.subAccounts,
      right: true,
    },
    {
      name: "DATE",
      selector: (row) => {
        return (
          <div>
            <div>{moment(row.dateCreated).format("DD/MM/YY")}</div>
            <div
              style={{
                color: "#7A7A7A",
                fontSize: "13px",
                lineHeight: "24px",
                fontWeight: "400",
                letterSpacing: "0.5px",
                textTransform: "capitalize",
                textAlign: "end",
              }}
            >
              {moment(row.dateCreated).format(`hh:mma`)}
            </div>
          </div>
        );
      },
    },
    {
      name: "",
      cell: (row, index, column, id) =>
        showCard === index ? (
          <InstitutionTableCard
            row={row}
            refVar={ref}
            showContact={showContact}
            setShowContact={setShowContact}
            showCard={showCard}
            setShowCard={setShowCard}
          />
        ) : (
          <KebabIcon onClick={() => setShowCard(index)} />
        ),
      allowOverflow: true,
      center: 1,
    },
  ];

  //Check outsideclick
  useEffect(() => {
    const checkOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowCard(null);
        setShowContact(false);
      }
    };
    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [showCard]);

  return {
    institutionTableColumns,
    showCard,
    setShowCard,
    showContact,
    setShowContact,
  };
};

export default useGetInstitutionColumns;
