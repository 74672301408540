//For formatting amount
export const formatCurrency = function (number) {
  // Convert number to string
  const strNumber = String(number);

  // Split the number into whole and decimal parts
  const [wholePart, decimalPart] = strNumber.split(".");

  // Format the whole part with commas
  let formattedNumber = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Add currency symbol
  formattedNumber = "₦" + formattedNumber;

  // Round up the decimal part to 2 decimal places
  if (decimalPart) {
    const roundedDecimal =
      Math.ceil(parseFloat("0." + decimalPart) * 100) / 100;
    formattedNumber += "." + roundedDecimal.toFixed(2).slice(-2);
  } else {
    formattedNumber += ".00";
  }
  return formattedNumber;
};

//Remove underscore from text and capitalize
export const formatText = function (str) {
  // Replace underscores with spaces
  const stringWithSpaces = str.replace(/_/g, " ");

  // Convert to capitalized case
  const capitalizedString = stringWithSpaces
    .toLowerCase()
    .replace(/\b\w/g, (match) => match.toUpperCase());

  return capitalizedString;
};

//capitalize word
export const capitalizeText = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//js query builder
export const buildQuery = (params) => {
  const queryParams = [];

  for (const key in params) {
    const value = params[key];
    if (value !== null && value !== undefined && value !== "") {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      queryParams.push(`${encodedKey}=${encodedValue}`);
    }
  }

  return queryParams.join("&");
};
