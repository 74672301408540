import styled from "styled-components";

const ReceiptStyle = styled.div`
  /* width: 502px; */
  margin: auto;
  font-family: "DM Sans";

  header {
    border-bottom: 0.5px solid #bebdbd;
    padding: 16px;
    padding-bottom: 8px;

    .receipt-heading {
      display: flex;
      justify-content: space-between;

      h3 {
        color: #0c0e13;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
      }

      p {
        color: var(--grayscale-300, #667085);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    p {
      /* color: #03874a; */
      color: ${({ transactionType }) =>
        transactionType === "credit" ? `#03874A` : `#C21306`};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;

      letter-spacing: 0.16px;
      text-align: center;
    }
  }

  #recipient-section,
  #sender-section,
  #transaction-section,
  #balance-section {
    padding: 16px;
    padding-bottom: 6px;
    font-size: 14px;
    border-bottom: 0.5px solid #bebdbd;

    h4 {
      margin-bottom: 8px;
    }
    .recipient-details,
    .sender-details,
    .transaction-details,
    .balance-details {
      font-size: 10px;
      font-weight: 400;

      .recipient-details-item,
      .sender-details-item,
      .transaction-details-item,
      .balance-details-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      }
    }
  }

  #button-section {
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }
  .key {
    color: #667085;
  }
  .value {
    color: #2b3241;
  }
`;

export { ReceiptStyle };
