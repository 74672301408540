import React from "react";

//dependencies
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";

//styled components
//components
import Dashboard from "../pages/dashboardPage/Dashboard";
import Accounts from "../pages/accountsPage/Accounts";
import SignIn from "../pages/signInPage/SignIn";
import Transactions from "../pages/transactionsPage/Transactions";
import AccountTransactions from "../pages/accountsPage/AccountTransactions";
import InstitutionRoutes from "./InstitutionRoutes";

//svg components
//data

const Router = () => {
  return (
    <Routes>
      <Route index path="/" element={<Navigate replace to="/signin" />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="institutions/*" element={<InstitutionRoutes />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path=":accountNo" element={<AccountTransactions />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/accounts/:accountNo" element={<AccountTransactions />} />
    </Routes>
  );
};

export default Router;
