export const sidebarMenuItems = [
  "Dashboard",
  "Institutions",
  "Accounts",
  "Transactions",
];

export const paginationOptions = {
  rowsPerPageText: "Rows per page:",
  rangeSeparatorText: "of",
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: "All",
};
