import React from "react";
import { StatsCardStyle } from "./StatsCardStyle";
import { ReactComponent as InstitutionLogo } from "../../../assets/icons/dashboardIcons/building.svg";

const StatsCard = ({ title, amount, text, logo }) => {
	return (
		<StatsCardStyle>
			<h3>{title}</h3>

			<div className='card-summary'>
				<p>
					<span>{amount}</span> {text}
				</p>
				{logo}
			</div>
		</StatsCardStyle>
	);
};

export default StatsCard;
