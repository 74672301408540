import React from "react";

const CardListItem = ({ firsLine, secondLine }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				// marginRight: "52px",
			}}
		>
			<p>{firsLine}</p>
			<p className='content'>{secondLine}</p>
		</div>
	);
};

export default CardListItem;
