import React, { useEffect, useState } from "react";

//dependencies
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import moment from "moment/moment";
import { Bar } from "react-chartjs-2";
import { http } from "../../../axios-https";
import env from "../../../env";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);
//styled components
//components
//svg components
//data

const ActiveAccounts = ({ totalFarmers }) => {
  const [noOfAccounts, setNoOfAccounts] = useState([]);
  const [past7Days, setPast7days] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // legend: {
      // 	position: "top",
      // },
      // title: {
      // 	display: true,
      // 	text: "Chart.js Bar Chart",
      // },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
        // title: {
        // 	display: true,
        // 	text: "X Axis Label",
        // 	color: "red", // Color of the x-axis label
        // },
        ticks: {
          color: "#000", // Color of the x-axis tick labels
        },
        // gridLines: {
        // 	color: "green", // Color of the x-axis line
        // 	lineWidth: 2, // Width of the x-axis line
        // },
      },
      y: {
        display: true,
        grid: {
          display: false, // Hide the y-axis grid lines
        },
        // title: {
        // 	display: true,
        // 	text: "Y Axis Label",
        // 	color: "red", // Color of the y-axis label
        // },
        ticks: {
          color: "#000", // Color of the y-axis tick labels
        },
        gridLines: {
          color: "#000", // Color of the y-axis line
          lineWidth: 2, // Width of the y-axis line
        },
      },
    },
  };

  const getPast7days = () => {
    //find out if date is Today's date
    function isSameDate(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    }

    // Get the current date
    const currentDate = new Date();

    // Create an array of seven dates using Array.from
    const datesArray = Array.from({ length: 7 }, (_, index) => {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - (6 - index));

      if (isSameDate(date, currentDate)) {
        return "Today";
      } else {
        return moment(date).format("MMMM Do");
      }
    });

    // Output the array of dates
    return datesArray;
  };

  const labels = getPast7days();

  const data = {
    labels,
    datasets: [
      {
        label: "Active Accounts",
        data: noOfAccounts,
        backgroundColor: [
          "#4F7C06",
          "#4F7C06",
          "#4F7C06",
          "#4F7C06",
          "#4F7C06",
          "#4F7C06",
          "#203202",
        ],
        barThickness: 32,
        borderRadius: 4,
      },
    ],
  };

  const getActiveAccounts = () => {
    http
      .get(`${env.greenbanker_admin_base_url}/active-users/7`)
      .then((response) => {
        // console.log(response.data.data);

        const daysList = response.data.data.map((item) =>
          moment(item.key).format("MMMM Do")
        );

        setPast7days([...daysList]);

        const accountList = response.data.data.map((item) => item.value);
        setNoOfAccounts([...accountList]);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getActiveAccounts();
  }, []);

  return (
    <>
      <div className="graph-title-container">
        <div className="label-active-accounts">
          <h3>Active Accounts</h3>
          <p>Past 7 days</p>
        </div>
        <div className="label-todays-active-accounts">
          <h5>TODAY'S ACTIVE ACCOUNTS</h5>
          <h3>{noOfAccounts[6]}</h3>
          <p>out of {totalFarmers} total accounts</p>
        </div>
      </div>

      <div
        style={{
          // backgroundColor: "grey",
          height: "200px",
          width: "650px",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

export default ActiveAccounts;
