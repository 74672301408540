import React from "react";

//dependencies
import { useLocation } from "react-router-dom";
//styled components
//components
import InstitutionAccounts from "./InstitutionAccounts";
import InstitutionTransactions from "./InstitutionTransactions";
//svg components
//data and utils

const InstitutionDetails = () => {
  const location = useLocation();
  if (location.pathname.includes("transactions")) {
    return <InstitutionTransactions />;
  }
  if (location.pathname.includes("institution")) {
    return <InstitutionAccounts />;
  } else {
    return <div>Not Found</div>;
  }
};

export default InstitutionDetails;
