import React, { useRef } from "react";

//dependencies
import { useNavigate } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../../redux/slices/modalSlice";
import { setSubAccounts } from "../../../redux/slices/subAccountsSlice";
//styled components
import { CustomCardStyle } from "./CardStyle";
//components
import CardListItem from "./CardListItem";
//svg components
import { ReactComponent as PersonIcon } from "../../../assets/icons/cardIcons/personAvatar.svg";
import { ReactComponent as MagazineIcon } from "../../../assets/icons/cardIcons/magazineIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/cardIcons/downloadIcon.svg";
import { ReactComponent as TwoPeopleIcon } from "../../../assets/icons/cardIcons/twoPeopleIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cardIcons/closeIcon.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/cardIcons/cardIcon.svg";
import { ReactComponent as TransactionIcon } from "../../../assets/icons/cardIcons/squigglyArrow.svg";

//data

const InstitutionTableCard = ({
  refVar,
  showContact,
  setShowContact,
  showCard,
  setShowCard,
  row,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleToggleModal = () => {
    setShowCard(false);
    dispatch(toggleModal());
  };

  const handleSubAccounts = () => {
    const subAccountsData = {
      institutionName: row.institutionName,
      institutionId: row.institutionId,
    };
    dispatch(setSubAccounts(subAccountsData));
  };

  const handleDownloadClick = () => {
    handleToggleModal();
    handleSubAccounts();
  };

  if (showContact === true) {
    return (
      <CustomCardStyle ref={refVar}>
        <li>
          <CardIcon style={{ marginRight: "18px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "auto",
            }}
          >
            <h5>{row.contactPersonName}</h5>
            <p>Contact Person</p>
          </div>
          <CloseIcon
            onClick={() => {
              setShowContact(false);
              setShowCard(false);
            }}
            style={{
              marginRight: "15px",
            }}
          />
        </li>
        <li>
          <CardListItem firsLine="Email" secondLine={row.contactPersonEmail} />
        </li>
        <li>
          <CardListItem
            firsLine="Phone no."
            secondLine={row.contactPersonPhone}
          />
        </li>
        <li>
          <CardListItem
            firsLine="BVN"
            secondLine={row.contactPersonBvn ? row.contactPersonBvn : "N/A"}
          />
        </li>
        <li>
          <CardListItem
            firsLine="NIN"
            secondLine={row.contactPersonNin ? row.contactPersonNin : "N/A"}
          />
        </li>
      </CustomCardStyle>
    );
  } else
    return (
      <CustomCardStyle ref={refVar}>
        <li className="kebab-list-item" onClick={() => setShowContact(true)}>
          <PersonIcon style={{ marginRight: "18px", cursor: "default" }} />
          Contact person details
        </li>
        <li className="kebab-list-item" onClick={handleDownloadClick}>
          <MagazineIcon style={{ marginRight: "18px" }} />
          View sub-accounts
        </li>
        <li className="kebab-list-item">
          <DownloadIcon style={{ marginRight: "18px" }} />
          View institution’s report
        </li>
        <li
          className="kebab-list-item"
          onClick={() =>
            navigate(`/institutions/${row.institutionName}`, {
              state: {
                data: {
                  institutionId: row.institutionId,
                  institutionName: row.institutionName,
                },
              },
            })
          }
        >
          <TwoPeopleIcon style={{ marginRight: "18px" }} />
          Institution’s customers
        </li>
        <li
          className="kebab-list-item"
          onClick={() => {
            navigate(`/institutions/${row.institutionName}'s transactions`, {
              state: {
                data: {
                  institutionId: row.institutionId,
                  institutionName: row.institutionName,
                },
              },
            });
          }}
        >
          <TransactionIcon style={{ marginRight: "18px" }} />
          Institution’s transactions
        </li>
      </CustomCardStyle>
    );
};

export default InstitutionTableCard;
