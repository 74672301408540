import { configureStore } from "@reduxjs/toolkit";
import modalSliceReducer from "../redux/slices/modalSlice";
import filterSliceReducer from "../redux/slices/transactionFilterSlice";
import receiptSliceReducer from "../redux/slices/receiptSlice";
import subAccountsSliceReducer from "../redux/slices/subAccountsSlice";
import filerParametersSliceReducer from "../redux/slices/filerParametersSlice";
import searchQuerySliceReducer from "../redux/slices/searchQuerySlice";
import paginationSliceReducer from "../redux/slices/paginationSlice";

export default configureStore({
  reducer: {
    toggle: modalSliceReducer,
    filter: filterSliceReducer,
    receipt: receiptSliceReducer,
    subAccounts: subAccountsSliceReducer,
    filterParameters: filerParametersSliceReducer,
    searchQuery: searchQuerySliceReducer,
    pagination: paginationSliceReducer,
  },
});
