import React, { useEffect, useState } from "react";

//dependencies
import env from "../../env";
import { http } from "../../axios-https";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
//redux
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/slices/modalSlice";

//styled components
import { ReceiptStyle } from "./TransactionReciptsStyle";
//components
import { ButtonGreenFilled, ButtonWhiteOutline } from "../button/Button";
//svg components
import { ReactComponent as CloseIcon } from "../../assets/icons/cardIcons/closeIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow-left.svg";
//data and utils

const TransactionReceipts = () => {
  const dispatch = useDispatch();
  const receipt = useSelector((state) => state.receipt);
  const [institutionAccount, setInstitutitutionAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const handleCloseModal = () => {
    dispatch(toggleModal());
  };

  const fetchNameFromId = () => {
    http
      .get(
        `${env.greenbanker_base_url}/institutions/accounts?institutionId=${receipt.institutionId}`
      )
      .then((response) =>
        setInstitutitutionAccount(response.data.data[0].nuban)
      )
      .catch((error) => console.log(error));
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById("receipt");

    // Hide the button elements before downloading
    const hiddenElements = document.getElementById("section-to-hide");
    hiddenElements.style.display = "none";

    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save(
        `${
          receipt.recptAccountName ? receipt.recptAccountName : "Transaction"
        } ${receipt.transactionType} ${receipt.dataAndTime}.pdf`
      );

      setLoading(false);
      // Show the button elements after downloading
      hiddenElements.style.display = "";
    });
  };

  const SenderSection = () => {
    return (
      <section id="sender-section">
        <h4>Sender's Details</h4>

        <div className="sender-details">
          <div className="sender-details-item">
            <p className="key">Account name:</p>
            <p className="value">
              {" "}
              {receipt.transactionType === "credit"
                ? `InstitutionId: ${receipt.institutionId}`
                : receipt.senderAccountName}
            </p>
          </div>
          <div className="sender-details-item">
            <p className="key">Account no:</p>
            <p className="value">
              {" "}
              {receipt.transactionType === "credit"
                ? receipt.recptAccountNo
                : receipt.senderAccountNo}
            </p>
          </div>
          <div className="sender-details-item">
            <p className="key">Bank name:</p>
            <p className="value">
              {" "}
              {receipt.transactionType === "credit"
                ? receipt.senderBankName
                : receipt.recptBankName}
            </p>
          </div>
        </div>
      </section>
    );
  };

  const RecepientSection = () => {
    return (
      <section id="recipient-section">
        <h4>Recipient's Details</h4>

        <div className="recipient-details">
          <div className="recipient-details-item">
            <p className="key">Account name:</p>
            <p className="value">
              {" "}
              {receipt.transactionType === "credit"
                ? receipt.senderAccountName
                : receipt.recptAccountName}
            </p>
          </div>
          <div className="recipient-details-item">
            <p className="key">Account no:</p>
            <p className="value">
              {" "}
              {receipt.transactionType === "credit"
                ? receipt.senderAccountNo
                : receipt.recptAccountNo}
            </p>
          </div>
          <div className="recipient-details-item">
            <p className="key">Bank name:</p>
            <p className="value">
              {" "}
              {receipt.transactionType === "credit"
                ? receipt.recptBankName
                : receipt.senderBankName}
            </p>
          </div>
        </div>
      </section>
    );
  };

  useEffect(() => {
    fetchNameFromId();
  }, [institutionAccount]);

  return (
    <ReceiptStyle transactionType={receipt.transactionType} id="receipt">
      {/* {console.log(receipt.amount)} */}
      {loading ? (
        <h3 style={{ padding: "20px" }}>Downloading Receipt... </h3>
      ) : (
        <>
          <header>
            <div className="receipt-heading">
              <div>
                <h3>Tanadi MFB</h3>
                <p>Transaction Receipt</p>
              </div>
              <div>
                <CloseIcon onClick={handleCloseModal} />
              </div>
            </div>

            <p>
              {receipt.transactionType === "credit" ? "Credit" : "Debit"}{" "}
              Transaction
            </p>
          </header>

          {receipt.transactionType === "credit" ? (
            <>
              <SenderSection />
              <RecepientSection />
            </>
          ) : (
            <>
              <RecepientSection />
              <SenderSection />
            </>
          )}
          {/* <SenderSection />
          <RecepientSection /> */}

          <section id="transaction-section">
            <h4>Transaction Details</h4>

            <div className="transaction-details">
              <div className="transaction-details-item">
                <p className="key">Amount:</p>
                <p className="value">{receipt.amount}</p>
              </div>
              <div className="transaction-details-item">
                <p className="key">Status:</p>
                <p className="value">{receipt.status}</p>
              </div>
              <div className="transaction-details-item">
                <p className="key">Channel:</p>
                <p className="value">{receipt.channel}</p>
              </div>
              <div className="transaction-details-item">
                <p className="key">Narration:</p>
                <p className="value">{receipt.narration}</p>
              </div>
              <div className="transaction-details-item">
                <p className="key">Reference No:</p>
                <p className="value">{receipt.referenceNo}</p>
              </div>
              <div className="transaction-details-item">
                <p className="key">Session ID:</p>
                <p className="value">{receipt.sessionId}</p>
              </div>
              <div className="transaction-details-item">
                <p className="key">Date and Time:</p>
                <p className="value">{receipt.dataAndTime}</p>
              </div>
            </div>
          </section>

          <section id="section-to-hide">
            <section id="balance-section">
              <h4>Balance info</h4>

              <div className="balance-details">
                <div className="balance-details-item">
                  <p className="key">Old balance:</p>
                  <p className="value">{receipt.oldBalance}</p>
                </div>
                <div className="balance-details-item">
                  <p className="key">New balance:</p>
                  <p className="value">{receipt.newBalance}</p>
                </div>
              </div>
            </section>
            <section id="button-section">
              <ButtonWhiteOutline
                name="Back"
                icon={
                  <LeftArrow style={{ marginTop: "4px", marginRight: "8px" }} />
                }
                style={{ width: "156px", marginRight: "10px" }}
                handleClick={handleCloseModal}
              />
              <ButtonGreenFilled
                name="Download"
                icon={
                  <DownloadIcon
                    style={{ marginTop: "3px", marginRight: "8px" }}
                  />
                }
                style={{ width: "156px" }}
                handleClick={() => {
                  setLoading(true);
                  handleDownloadPDF();
                }}
              />
            </section>
          </section>
        </>
      )}
    </ReceiptStyle>
  );
};

export default TransactionReceipts;
