import React, { useEffect } from "react";

import "./InputField.css";

const InputField = ({
	label,
	placeholder,
	type,
	style,
	value,
	onChange,
	disabled,
	logo,
	inputStyle,
	spanStyle,
	handleClick,
	readOnly,
	pattern,
}) => {
	return (
		<div style={style} className='flex-column input-field'>
			<label htmlFor='' className='label'>
				{label}
			</label>
			<div>
				<span style={spanStyle}>{logo}</span>
				<input
					disabled={disabled}
					className='input'
					type={type}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onClick={handleClick}
					style={inputStyle}
					readOnly={readOnly}
					pattern={pattern}
				/>
			</div>
		</div>
	);
};

export default InputField;
