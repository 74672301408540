import styled from "styled-components";

const DashboardStyle = styled.div`
	font-family: "DM Sans";
	margin: 44px 54px 50px 48px; //54px to account for scrollbar

	h1 {
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		letter-spacing: 0.01em;
		color: #1c2c02;
		margin-bottom: 48px;
	}

	.accounts-overview {
		display: flex;
		/* justify-content: space-between; */
		/* flex-wrap: wrap; */
		gap: 26px;
	}
`;

const StatsCardWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 44px;
	gap: 22px;
`;

export { DashboardStyle, StatsCardWrapper };
