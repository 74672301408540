import { useState, useEffect } from "react";

//dependencies
//redux
import {
  addToTransactionChannel,
  addToTransactionStatus,
  addToTransactionType,
  deleteFromTransactionChannel,
  deleteFromTransactionStatus,
  deleteFromTransactionType,
} from "../../../redux/slices/filerParametersSlice";
import { useSelector, useDispatch } from "react-redux";
//styled components
import { FilterBody, FilterBackground } from "./TransactionFilterStyle";
//components
import FilterCheckbox from "../FilterCheckbox";
import { Scrollbars } from "rc-scrollbars";
//svg components
import { ReactComponent as CloseIcon } from "../../../assets/icons/cardIcons/closeIcon.svg";
import { toggleFilter } from "../../../redux/slices/transactionFilterSlice";
//data and utils
import { formatText } from "../../../utils/utils";

import "./scrollbar.css";

const TransactionFilter = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState("yes");

  const dispatch = useDispatch();

  const searchQuery = useSelector((state) => state.searchQuery);

  const filterParameters = useSelector((state) => state.filterParameters);
  const handleToggleFilter = () => {
    dispatch(toggleFilter());
  };

  //filter parameters
  const transactionStatus = ["SUCCESSFUL", "PENDING", "FAILED", "REVERSED"];
  const transactionChannel = [
    "LOCAL_TRANSFER",
    "INTER_BANK_TRANSFER",
    "CARD_WITHDRAWAL",
    "CARD_TRANSFER",
    "TRANSFER_FEE",
    "ELECTRONIC_LEVY",
  ];
  const transactionType = ["CREDIT", "DEBIT"];

  const updateTransactionStatus = (status) => {
    if (filterParameters.transactionStatus.includes(status)) {
      dispatch(deleteFromTransactionStatus(status));
    } else {
      dispatch(addToTransactionStatus(status));
    }
  };
  const updateTransactionChannel = (channel) => {
    if (filterParameters.transactionChannel.includes(channel)) {
      dispatch(deleteFromTransactionChannel(channel));
    } else {
      dispatch(addToTransactionChannel(channel));
    }
  };
  const updateTransactionType = (type) => {
    if (filterParameters.transactionType.includes(type)) {
      dispatch(deleteFromTransactionType(type));
    } else {
      dispatch(addToTransactionType(type));
    }
  };

  return (
    <FilterBackground>
      <FilterBody>
        <header>
          <h3>Filter by</h3>
          <CloseIcon onClick={handleToggleFilter} />
        </header>

        <section id="transaction-status-section">
          <h3>Transaction Status</h3>
          {transactionStatus.map((status) => (
            <div className="transaction-status-item">
              <FilterCheckbox
                condition={filterParameters.transactionStatus.includes(status)}
                handleCheckboxClick={() => updateTransactionStatus(status)}
              />
              <p>{formatText(status)}</p>
            </div>
          ))}
        </section>
        <section id="transaction-channel-section">
          <h3>Transaction Channel</h3>
          {transactionChannel.map((channel) => (
            <div className="transaction-channel-item">
              <FilterCheckbox
                condition={filterParameters.transactionChannel.includes(
                  channel
                )}
                handleCheckboxClick={() => updateTransactionChannel(channel)}
              />
              <p>{formatText(channel)}</p>
            </div>
          ))}
        </section>
        <section id="transaction-type-section">
          <h3>Transaction Type</h3>
          {transactionType.map((type) => (
            <div className="transaction-type-item">
              <FilterCheckbox
                condition={filterParameters.transactionType.includes(type)}
                handleCheckboxClick={() => updateTransactionType(type)}
              />
              <p>{formatText(type)}</p>
            </div>
          ))}
        </section>
      </FilterBody>
    </FilterBackground>
  );
};

export default TransactionFilter;
