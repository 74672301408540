import styled from "styled-components";

const CustomCardStyle = styled.ul`
  width: 260px;
  border: 0.5px solid #eff1f4;
  border-radius: 6px;
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0c0e13;
  box-shadow: 0px 8px 12px 3px rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 500;
  background-color: #fff;
  top: -60%;
  right: 5%;
  cursor: default;
  /* right: 0;
	top: 100%; */

  li {
    list-style: none;
    /* width: 260px; */
    height: 53px;
    border-bottom: 0.5px solid #dbdfe7;
    display: flex;
    align-items: center;
    padding-left: 20px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #667085;
    }

    .content {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #0c0e13;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1c2c02;
    }
  }
  li:last-child {
    border-bottom: none;
  }

  .kebab-list-item {
    :hover {
      background-color: #ecf9d8;
    }
  }
`;

const CustomCardStyle2 = styled.ul`
  width: 260px;
  border: 0.5px solid #eff1f4;
  border-radius: 6px;
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0c0e13;
  box-shadow: 0px 8px 12px 3px rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 500;
  background-color: #fff;
  top: -40%;
  right: 5%;
  cursor: default;
  /* right: 0;
	top: 100%; */

  li {
    list-style: none;
    /* width: 260px; */
    height: 53px;
    border-bottom: 0.5px solid #dbdfe7;
    display: flex;
    align-items: center;
    padding-left: 20px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #667085;
    }

    .content {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #0c0e13;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1c2c02;
    }
  }
  li:last-child {
    border-bottom: none;
  }

  .kebab-list-item {
    :hover {
      background-color: #ecf9d8;
    }
  }
`;

const CustomCardStyle3 = styled.ul`
  width: 260px;
  border: 0.5px solid #eff1f4;
  border-radius: 6px;
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0c0e13;
  box-shadow: 0px 8px 12px 3px rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 500;
  background-color: #fff;
  top: 10%; //35%
  right: 5%;
  cursor: default;
  /* right: 0;
	top: 100%; */

  li {
    list-style: none;
    /* width: 260px; */
    height: 53px;
    border-bottom: 0.5px solid #dbdfe7;
    display: flex;
    align-items: center;
    padding-left: 20px;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #667085;
    }

    .content {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #0c0e13;
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1c2c02;
    }
  }
  li:last-child {
    border-bottom: none;
  }

  .kebab-list-item {
    :hover {
      background-color: #ecf9d8;
    }
  }
`;

export { CustomCardStyle, CustomCardStyle2, CustomCardStyle3 };
