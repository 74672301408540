import React, { useEffect } from "react";

//dependencies
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { http } from "../../../axios-https";
import env from "../../../env";
import moment from "moment";
ChartJS.register(Tooltip, ArcElement, ChartDataLabels);

//styled components
//components
//svg components
//data

const BvnInfo = ({ totalFarmers, withBvn }) => {
  const getPercentage = (fig) => {
    return (fig / totalFarmers) * 100;
  };

  const data = {
    labels: ["Label 1", "Label 2"],
    datasets: [
      {
        // data: [89, 11],

        data: [
          getPercentage(withBvn).toFixed(1),
          getPercentage(totalFarmers - withBvn).toFixed(1),
        ],
        backgroundColor: ["#4F7C06", "#DCE5CD"],
        borderWidth: 1,
        rotation: 60,
      },
    ],
  };

  const options = {
    responsive: true, // Enable responsiveness
    maintainAspectRatio: false, // Allow chart to stretch to the container size

    plugins: {
      datalabels: {
        color: ["white", "black"],
        anchor: "center",
        align: "center",
        offset: 10,
        // font: {
        // weight: "bold",
        // },
        position: "outside",
        formatter: (value, context) => {
          if (context.dataset.data[context.dataIndex] > 5) {
            // Show labels only for slices with a value greater than 5
            return `(${value}%)`;
          } else {
            return null; // Hide labels for slices with a value less than or equal to 5
          }
        },
      },
    },
  };

  return (
    <>
      <h3 className="chart-title">BVN Information</h3>

      <div className="chart-container">
        <div className="pie-chart-container">
          <Pie data={data} options={options} />
        </div>

        <div className="chart-legend">
          <h5>total accounts</h5>
          <h2>{totalFarmers}</h2>
          <div className="chart-label" style={{ marginBottom: "20px" }}>
            <div
              className="chart-label-swatch"
              style={{
                backgroundColor: "#3F6305",
              }}
            ></div>
            Accounts with BVN
          </div>
          <div className="chart-label">
            <div
              className="chart-label-swatch"
              style={{
                backgroundColor: "#B9CB9B",
              }}
            ></div>
            Accounts without BVN
          </div>
        </div>
      </div>
    </>
  );
};

export default BvnInfo;
