import styled from "styled-components";

const SidebarMenuContainer = styled.div`
	width: 216px;
	min-height: 100vh;
	background-color: #3a4704;

	font-family: "DM Sans";
	font-weight: 500;
	font-weight: 500;
	font-size: 16px;
`;

const SidebarMenuTitle = styled.div`
	color: white;
	font-size: 20px;
	display: flex;
	margin-top: 25px;
	margin-left: 13px;
	margin-bottom: 60px;
`;

const SidebarMenuList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	list-style-type: none;
	/* width: 184px; */

	a {
		text-decoration: none;
		padding: 14px 16px;
		margin-bottom: 16px;
		width: 178px;
		border-radius: 6px;

		&:hover {
			background-color: #182501;
		}
	}

	.active {
		background-color: #182501;
	}
`;

const SidebarMenuItem = styled.li`
	color: white;
	display: flex;
	align-items: center;
	text-decoration: none;
	/* color: inherit; */

	/* padding: 14px 16px;
	margin-bottom: 16px;
	width: 184px;
	border-radius: 6px; */

	/* &:hover {
		background-color: #81a449;
	} */

	a {
		/* display: flex;
		align-items: center;
		text-decoration: none;
		color: inherit; */
	}
`;

export {
	SidebarMenuContainer,
	SidebarMenuTitle,
	SidebarMenuList,
	SidebarMenuItem,
};
