import { NavbarStyle, AccountProfile } from "./NavbarStyles";
import Avatar from "../../components/avatar/Avatar";
import { ReactComponent as DropdownArrow } from "../../assets/icons/sidebarMenuIcons/dropdownArrow.svg";
import UserProfile from "../userProfile/UserProfile";
import env from "../../env";

const Navbar = ({ showProfile, setShowProfile, navRef }) => {
  const userDetails = env.getUser();
  return (
    <div ref={navRef}>
      <NavbarStyle onClick={() => setShowProfile(!showProfile)}>
        <h2>Greenbanker</h2>
        <div className="profile-bar">
          <Avatar name="Crust Admin" size={35} round color={"#ED8D10"} />
          <div className="account-name">
            <h5>{userDetails.firstName + " " + userDetails.lastName}</h5>
            <p>Admin Management</p>
          </div>
          <DropdownArrow />
        </div>
      </NavbarStyle>

      {showProfile && <UserProfile />}
    </div>
  );
};

export default Navbar;
