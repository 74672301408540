import { createSlice } from "@reduxjs/toolkit";

const transactionFilterSlice = createSlice({
  name: "filter",
  initialState: false,
  reducers: {
    toggleFilter: (state) => !state,
  },
});

//this is for dispatch
export const { toggleFilter } = transactionFilterSlice.actions;

//this is for reducer
export default transactionFilterSlice.reducer;
