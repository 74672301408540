import React, { useState } from "react";
import { Button } from "../../components/button/Button";
// import InputField from "../../components/inputField/InputField";
import InputField from "../../components/input/InputField";

import { ReactComponent as PasswordLogo } from "../../assets/icons/eye.svg";
import { CgSpinner } from "react-icons/cg";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import env from "../../env";
import encryptData from "../../encrypt";

import "./SignIn.css";
import { useEffect } from "react";

const SignIn = ({ title }) => {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const [hasError, setHasError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const signIn = () => {
    let encryptedPassword = encryptData(password);
    setIsLoading(true);

    const formData = {
      username: email.toLowerCase(),
      password: encryptedPassword,
    };

    // console.log(formData);

    axios
      .post(`${env.greenbanker_admin_base_url}/login`, formData)
      .then((response) => {
        console.log(response);

        env.storeUser(
          response.data.data.access_token,
          response.data.data.greenBanker
        );

        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data.message) {
          setErrorMessage(error.response.data.message);
          return;
        }
        if (error.message) {
          setErrorMessage(error.message);
          return;
        }
        setErrorMessage("An Error Occured");
      })
      .then(() => {
        setIsLoading(false);
        setTimeout(() => {
          setErrorMessage("");
        }, 7000);
      });
  };

  return (
    <div className="sign-in center">
      <h1 className="title">Greenbanker Admin</h1>

      {/* {errorMessage && (
				<div className='error-message'>
					<ErrorMessage
						text={errorMessage}
						handleClick={() => {
							setHasError(false);
							setErrorMessage("");
						}}
					/>
				</div>
			)} */}

      <p style={{ color: "red" }}>{errorMessage}</p>

      <InputField
        label="Email address"
        placeholder="Enter email address"
        type="email"
        style={{ marginBottom: "24px" }}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        // disabled={modalData.showModal}
      />

      <InputField
        label="Password"
        placeholder="Enter password"
        type={!showPassword ? "password" : "text"}
        style={{ marginBottom: "56px" }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        // disabled={modalData.showModal}
        logo={<PasswordLogo onClick={() => setShowPassword(!showPassword)} />}
      />

      <Button
        name={
          isLoading ? (
            <CgSpinner icon="spinner" className="spinner" />
          ) : (
            "Sign in"
          )
        }
        className={`btn btn-${
          !(password && password) ? "grey" : "green"
        }-filled`}
        handleClick={() => signIn()}
        disabled={!(email && password)}
      />
    </div>
  );
};

export default SignIn;
