import React, { useEffect, useState } from "react";

//dependencies
import env from "../../env";
import axios from "axios";
import { http } from "../../axios-https";

import useGetTransactions from "../../customHooks/Transactions/useGetTransactionsApi";

//styled components
import { DashboardStyle, StatsCardWrapper } from "./DashboardStyles";

//higher order components
import PagesWrapper from "../pagesWrapper/PagesWrapper";
import TestHOC from "../../HOC/NavbarSidemenuHOC";

//components
import StatsCard from "../../components/dashboardComponents/statsCard/StatsCard";
import AccountOverviewContainer from "../../components/dashboardComponents/accountOverview/AccountOverviewContainer";
import AccountListTotal from "../../components/dashboardComponents/accountListTotal/AccountListTotal";
//svg components
import { ReactComponent as InstitutionLogo } from "../../assets/icons/dashboardIcons/building.svg";
import { ReactComponent as FarmersLogo } from "../../assets/icons/dashboardIcons/People.svg";
import { ReactComponent as CardsLogo } from "../../assets/icons/dashboardIcons/Cards.svg";

//data

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalInstitutions, setTotalInstitutions] = useState(null);
  const [totalFarmers, setTotalFarmers] = useState(null);
  const [withBvn, setWithBvn] = useState(null);
  const [totalAccounts, setTotalAccounts] = useState(null);

  const { transactionList } = useGetTransactions();

  const getDashboardStats = () => {
    http
      .get(`${env.greenbanker_admin_base_url}/dashboard-statistics`)
      .then((response) => {
        console.log(response.data.data);
        setTotalInstitutions(response.data.data.totalInstitutions);
        setTotalFarmers(response.data.data.totalFarmers);
        setTotalAccounts(response.data.data.totalInstitutionAccounts);
        setWithBvn(response.data.data.totalFarmersWithBvn);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    console.log(transactionList);
    getDashboardStats();
  }, []);
  return (
    <DashboardStyle>
      <h1 className="dashboard-title">Dashboard</h1>

      {/* STATS CARD */}
      <StatsCardWrapper>
        <StatsCard
          title="Institutions"
          amount={isLoading ? "-" : totalInstitutions}
          text="in total"
          logo={<InstitutionLogo />}
        />
        <StatsCard
          title="Onboarded Farmers"
          amount={isLoading ? "-" : totalFarmers}
          text="in total"
          logo={<FarmersLogo />}
        />
        <StatsCard
          title="Institution Accounts"
          amount={isLoading ? "-" : totalAccounts}
          text="in total"
          logo={<CardsLogo />}
        />
      </StatsCardWrapper>

      {/* INFOGRAPHICS  */}
      <div className="accounts-overview">
        <AccountOverviewContainer
          totalFarmers={totalFarmers}
          withBvn={withBvn}
        />
        <AccountListTotal />
      </div>
    </DashboardStyle>
  );
};

export default TestHOC(Dashboard);
