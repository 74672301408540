import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  /* background-color: white;
  margin: 10% auto;
  padding: 20px;
  width: 35%; */

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dbdfe7;

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  width: 30%;
  height: 88vh;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
  /* position: absolute; */
  /* padding: 20px; */

  left: 0;
  right: 0;
  margin: 4% auto;
`;
// .download-modal {
// 	width: 422px;
// 	height: 555px;
// 	background: #fff;
// 	z-index: 100 !important;
// 	border-radius: 8px;
// 	position: absolute;
// 	left: 0;
// 	right: 0;
// 	margin-left: auto;
// 	margin-right: auto;
// 	top: 8%;
// }

export { ModalBackground, ModalBody };
