import React, { useState, useRef, useEffect } from "react";

//dependencies
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";

//custom hooks

import useGetTransactionsApi from "../../customHooks/Transactions/useGetTransactionsApi";
import useGetTransactionsColumns from "../../customHooks/Transactions/useGetTransactionColumns";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRowsPerPage,
  setCurrentPage,
} from "../../redux/slices/paginationSlice";
import { clearFilterParameters } from "../../redux/slices/filerParametersSlice";
import { setSearchQuery } from "../../redux/slices/searchQuerySlice";

//styled components
import { TableDetailStyle } from "../TableStyle";

//components
import NavbarSidemenuHOC from "../../HOC/NavbarSidemenuHOC";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Avatar from "../../components/avatar/Avatar";

import { Modal } from "../../modals/Modal";
import TransactionReceipts from "../../components/transactionReceipts/TransactionReceipts";
import TransactionsSearchBar from "../../components/tableComponents/transactionsSearchBar/TransactionsSearchBar";
import TransactionFilter from "../../components/tableComponents/transactionFilterComponent/TransactionFilter";

//svg components

//data and utils
import { paginationOptions } from "../../data/data";
import { institutionTransactionStyles } from "../../data/tableStyles";

const AccountTransactions = () => {
  //local variables and functions
  const ref = useRef();
  const location = useLocation();
  const { accountName, accountNumber, institutionId } = location.state.data;

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeRowsPerPage = (rowsPerPageValue) => {
    dispatch(setRowsPerPage(rowsPerPageValue));
    dispatch(setCurrentPage(1));
  };

  // redux dispatch and selectors
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.filter);
  const paginationParams = useSelector((state) => state.pagination);

  //fetchTransactions
  const id = null;
  const acctNo = accountNumber;
  const { transactionList, loading, totalTransactions, pageCount } =
    useGetTransactionsApi(id, acctNo);

  //fetch table column
  const {
    transactionTableColumns,
    showCard,
    setShowCard,
    showContact,
    setShowContact,
  } = useGetTransactionsColumns();

  //Check outsideclick
  useEffect(() => {
    const checkOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowCard(null);
        setShowContact(false);
      }
    };
    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, [showCard]);

  useEffect(() => {
    dispatch(clearFilterParameters());
    dispatch(setSearchQuery(""));

    return () => {
      dispatch(clearFilterParameters());
      dispatch(setSearchQuery(""));
    };
  }, []);

  return (
    <>
      <Modal>
        <TransactionReceipts />
      </Modal>

      {/* toggle display filter */}
      {showFilter && <TransactionFilter />}
      <TableDetailStyle>
        <div className="back-button">
          <Breadcrumb institutionId={institutionId} />
        </div>
        <div className="detail-summary-container">
          {console.log(location.state.data)}
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Avatar name={accountName} round size={60} />
            <div>
              <h1>{accountName}</h1>
              <p>{totalTransactions} transactions</p>
            </div>
          </div>

          <div className="institutionId-wrapper">
            <p>Acc Number</p>
            <h4>{accountNumber}</h4>
          </div>
        </div>

        <TransactionsSearchBar />

        <DataTable
          columns={transactionTableColumns}
          data={transactionList}
          customStyles={institutionTransactionStyles}
          responsive
          noTableStyles
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={pageCount * paginationParams.rowsPerPage}
          paginationPerPage={paginationParams.rowsPerPage}
          paginationRowsPerPageOptions={[50]}
          paginationComponentOptions={paginationOptions}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </TableDetailStyle>
    </>
  );
};

export default NavbarSidemenuHOC(AccountTransactions);
