import React, { useState } from "react";

import { ReactComponent as CheckedCheckbox } from "../../assets/icons/TableIcons/filterCheckboxChecked.svg";
import { ReactComponent as UncheckedCheckbox } from "../../assets/icons/TableIcons/filterCheckbox.svg";

const FilterCheckbox = ({ condition, handleCheckboxClick }) => {
  return (
    <div style={{ marginRight: "8px" }} onClick={handleCheckboxClick}>
      {condition ? <CheckedCheckbox /> : <UncheckedCheckbox />}
    </div>
  );
};

export default FilterCheckbox;
