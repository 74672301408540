import styled from "styled-components";

const AvatarElement = styled.div`
  min-width: 40px;
  .avatar {
    /* border-radius: 50px; */
  }
`;

export default AvatarElement;
