import React from "react";

import { InputStyles, InputContainer } from "./InputStyles";

const Input = ({
  value,
  placeholder,
  logo,
  logo_2,
  inputStyle,
  readonly,
  disabled,
  handleInputChange,
  handleInputClick,
}) => {
  return (
    <InputContainer onClick={handleInputClick}>
      <span id="logo-1">{logo}</span>
      <InputStyles
        placeholder={placeholder}
        logo={logo}
        style={inputStyle}
        readOnly={readonly}
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
      ></InputStyles>
      <span id="logo-2">{logo_2}</span>
    </InputContainer>
  );
};

export default Input;
