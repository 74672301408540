import styled from "styled-components";

const FilterBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const FilterBody = styled.div`
  height: 100vh;
  width: 30vw;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
  font-family: "DM Sans";
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dbdfe7;

    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #98a2b3;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  #transaction-status-section,
  #transaction-channel-section,
  #transaction-type-section {
    padding: 24px;
    border-bottom: 0.5px solid #dbdfe7;
    h3 {
      color: #0c0e13;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.16px;
      margin-bottom: 27px;
    }
    .transaction-status-item,
    .transaction-channel-item,
    .transaction-type-item {
      display: flex;
      margin-bottom: 16px;
      color: #667085;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.16px;
    }

    .transaction-status-item:last-child,
    .transaction-channel-item:last-child,
    .transaction-type-item:last-child {
      margin-bottom: 0px;
    }
  }
`;

export { FilterBackground, FilterBody };
