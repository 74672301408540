import React, { useEffect } from "react";

//dependencies

import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";

//custom hooks
import useGetTransactionsApi from "../../customHooks/Transactions/useGetTransactionsApi";
import useGetTransactionsColumns from "../../customHooks/Transactions/useGetTransactionColumns";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRowsPerPage,
  setCurrentPage,
} from "../../redux/slices/paginationSlice";
import { setSearchQuery } from "../../redux/slices/searchQuerySlice";
import { clearFilterParameters } from "../../redux/slices/filerParametersSlice";

//styled components
import { TableStyle } from "../TableStyle";

//components
import TransactionsSearchBar from "../../components/tableComponents/transactionsSearchBar/TransactionsSearchBar";
import NavbarSidemenuHOC from "../../HOC/NavbarSidemenuHOC";
import { Modal } from "../../modals/Modal";
import TransactionReceipts from "../../components/transactionReceipts/TransactionReceipts";
import TableWrapper from "../../components/TableWrapper";
import TransactionFilter from "../../components/tableComponents/transactionFilterComponent/TransactionFilter";

//svg components

//data and utils
import { institutionTransactionStyles } from "../../data/tableStyles";
import { paginationOptions } from "../../data/data";

import "../table.css";

const Transactions = () => {
  //fetchTransactions
  const { transactionList, loading, totalTransactions, pageCount } =
    useGetTransactionsApi();

  //redux dispatch and selectors
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.filter);
  const paginationParams = useSelector((state) => state.pagination);

  //fetch table column
  const { transactionTableColumns } = useGetTransactionsColumns();

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeRowsPerPage = (rowsPerPageValue) => {
    dispatch(setRowsPerPage(rowsPerPageValue));
    dispatch(setCurrentPage(1));
  };

  useEffect(() => {
    dispatch(clearFilterParameters());
    dispatch(setSearchQuery(""));

    return () => {
      dispatch(clearFilterParameters());
      dispatch(setSearchQuery(""));
    };
  }, []);

  return (
    <>
      <Modal>
        <TransactionReceipts />
      </Modal>

      {/* toggle display filter */}
      {showFilter && <TransactionFilter />}

      <TableStyle>
        <div
          className="institutions-title"
          onClick={() => console.log(paginationParams)}
        >
          <h1>All Transactions</h1>
          <p>{totalTransactions} transactions</p>
        </div>

        <TransactionsSearchBar />
        <TableWrapper>
          <DataTable
            columns={transactionTableColumns}
            data={transactionList}
            customStyles={institutionTransactionStyles}
            responsive
            noTableStyles
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={pageCount * paginationParams.rowsPerPage}
            paginationPerPage={paginationParams.rowsPerPage}
            paginationRowsPerPageOptions={[50]}
            paginationComponentOptions={paginationOptions}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </TableWrapper>
      </TableStyle>
    </>
  );
};

export default NavbarSidemenuHOC(Transactions);
