import React from "react";

const TableWrapper = ({ children }) => {
  return (
    <div style={{ boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.08)" }}>
      {children}
    </div>
  );
};

export default TableWrapper;
