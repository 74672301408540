import React, { useState, useEffect, useRef, forwardRef } from "react";

//dependencies
import env from "../../env";
import { http } from "../../axios-https";
import { useSelector, useDispatch } from "react-redux";

import { formatText, formatCurrency, buildQuery } from "../../utils/utils";

const useGetTransactionsApi = (id, acctNo) => {
  const [totalTransactions, setTotalTransactions] = useState("Fetching");
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);

  const filterParameters = useSelector((state) => state.filterParameters);
  const searchQuery = useSelector((state) => state.searchQuery);
  const paginationParams = useSelector((state) => state.pagination);

  const getTransactions = () => {
    const transactionQuery = {
      size: paginationParams.rowsPerPage,
      page: paginationParams.currentPage,
      filterInstitutionId: id,
      filterEndDate: filterParameters.dateRange.endDate,
      filterStartDate: filterParameters.dateRange.startDate,
      filterNameOrAccountNoOrOrganizationId: searchQuery || acctNo,
      filterTransactionType: filterParameters.transactionType.join(),
      filterTransactionStatus: filterParameters.transactionStatus.join(),
      filterTransactionChannel: filterParameters.transactionChannel.join(),
    };
    setLoading(true);
    http
      .get(
        `${env.greenbanker_admin_base_url}/transactions?${buildQuery(
          transactionQuery
        )}`
      )
      .then((response) => {
        // console.log(response.data.data.content);
        const listItem = response.data.data.content.map((item) => {
          return {
            id: item.id,
            name: formatText(item.name),
            userId: item.userId,
            accountName: item.accountName,
            transactionId: item.transactionInstitutionId,
            userId: item.userInstitutionId,
            institutionName: formatText(item.institutionName),
            accountNumber: item.accountNumber,
            amount: formatCurrency(item.amount),
            recipientAccountName: item.recipientAccountName,
            recipientAccountNumber: item.recipientAccountNumber,
            narration: item.narration,
            transactionType: item?.transactionType?.toLowerCase(),
            transactionStatus: item.transactionStatus,
            transactionService: formatText(item.transactionService),
            transactionServiceCategory: formatText(
              item.transactionServiceCategory
            ),
            platformTransactionRef: item.platformTransactionRef
              ? item.platformTransactionRef
              : "---",
            cbaTransactionRef: item.cbaTransactionRef,
            serviceProvider:
              item.serviceProvider === "CRUST"
                ? "Tanadi MFB"
                : item.serviceProvider,
            oldBalance: formatCurrency(item.oldBalance),
            newBalance: formatCurrency(item.newBalance),
            createdAt: item.createdAt,
          };
        });

        setPageCount(
          Math.ceil(
            response.data.data.totalElements / paginationParams.rowsPerPage
          )
        );

        setTotalTransactions(response.data.data.totalElements);
        setTransactionList([...listItem]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getTransactions();
  }, [searchQuery, paginationParams, filterParameters]);

  return { loading, transactionList, totalTransactions, pageCount };
};

export default useGetTransactionsApi;
