import styled from "styled-components";

const PagesWrapperStyle = styled.div`
	width: calc(100vw - 216px);
	top: 0;
	left: 14.1%;
	z-index: 0;
`;

export { PagesWrapperStyle };
