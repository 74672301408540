import React, { useState } from "react";

//dependencies
import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../redux/slices/modalSlice";
//styled components
import { ModalBody, ModalBackground } from "./ModalStyles";
//components
//svg components
//data and utils

export const Modal = ({ children }) => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.toggle);

  const handleCloseModal = () => {
    dispatch(toggleModal());
  };

  return (
    <>
      {showModal && (
        <ModalBackground onClick={handleCloseModal}>
          <ModalBody onClick={(e) => e.stopPropagation()}>{children}</ModalBody>
        </ModalBackground>
      )}
    </>
  );
};
