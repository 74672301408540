import React from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

import env from "../../../env";
import { httpClient } from "../../../axios-https";
import { ReactComponent as DownloadBtnGrey } from "../../../assets/icons/TableIcons/dwnldGrey.svg";
import { ReactComponent as DownloadBtnGreen } from "../../../assets/icons/TableIcons/dwnldGreen.svg";
import { buildQuery } from "../../../utils/utils";

const DownloadTransactionBtn = ({ condition }) => {
  const filterParameters = useSelector((state) => state.filterParameters);
  const paginationParams = useSelector((state) => state.pagination);
  const searchQuery = useSelector((state) => state.searchQuery);

  const downloadTransactions = () => {
    const transactionQuery = {
      size: paginationParams.rowsPerPage,
      page: paginationParams.currentPage,
      //   filterInstitutionId: id,
      filterEndDate: filterParameters.dateRange.endDate,
      filterStartDate: filterParameters.dateRange.startDate,
      filterNameOrAccountNoOrOrganizationId: searchQuery,
      filterTransactionType: filterParameters.transactionType.join(),
      filterTransactionStatus: filterParameters.transactionStatus.join(),
      filterTransactionChannel: filterParameters.transactionChannel.join(),
    };
    httpClient({
      url: `${
        env.greenbanker_admin_base_url
      }/transactions/download?${buildQuery(transactionQuery)}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Transaction Statement.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        // setErrorMessage(error.response.data.errors.otp);
        console.log(error);
      });
  };

  if (condition) {
    return <DownloadBtnGreen onClick={() => downloadTransactions()} />;
  } else {
    return <DownloadBtnGrey />;
  }
};

export default DownloadTransactionBtn;
