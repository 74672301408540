import styled from "styled-components";

const TotalListContainer = styled.div`
  width: 25.5vw;
  min-width: 250px;
  padding-top: 28px;
  padding-left: 20px;
  padding-right: 20px;
  /* height: 517px; */
  background-color: #fbfbfb;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  font-family: "DM Sans";

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin-bottom: 29px;
    color: #000000;
  }
`;

const CustomTableWrapper = styled.div`
  .rdt_Table {
    border-radius: 0;
  }
`;

export { TotalListContainer, CustomTableWrapper };
