import styled from "styled-components";

const InputContainer = styled.div`
	#logo-1 {
		position: absolute;
		margin-left: 13px;
		margin-top: 11px;
	}
	#logo-2 {
		position: absolute;
		margin-left: -30px;

		margin-top: 11px;
	}
`;
const InputStyles = styled.input`
	width: 348px;
	height: 40px;
	border: 1px solid #eff1f4;
	border-radius: 8px;
	outline: none;
	padding: 12px 16px 12px 40px;
	background-color: #fff;

	::placeholder {
		font-weight: 400;
		font-size: 12px;
		color: #98a2b3;
	}
`;

export { InputStyles, InputContainer };
