import styled from "styled-components";

const ButtonGreenStyle = styled.button`
  font-family: "DM Sans";
  color: white;
  background-color: #4f7c06;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.16px;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWhiteStyle = styled.div`
  font-family: "DM Sans";
  color: #4f7c06;
  background-color: white;
  border-radius: 6px;
  border: 1.5px solid #4f7c06;
  padding: 12px 12px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ButtonGreenStyle, ButtonWhiteStyle };
