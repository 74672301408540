import React from "react";

import env from "../../env";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { useNavigate } from "react-router-dom";
import { AccountProfile } from "./UserProfileStyles";
import { formatText } from "../../utils/utils";

const UserProfile = () => {
  const navigate = useNavigate();
  const userDetails = env.getUser();

  const logoutUser = () => {
    env.logOut();
    navigate("/");
  };

  return (
    <AccountProfile>
      <div className="profile-details-wrapper">
        <div className="profile-name-email">
          <h3>{userDetails.firstName + " " + userDetails.lastName}</h3>
          <p>{userDetails.email}</p>
        </div>

        <div className="role-tag">{formatText(userDetails.userType)}</div>
      </div>
      <div className="logout-wrapper" onClick={() => logoutUser()}>
        <h3>Log out</h3>

        <LogoutIcon />
      </div>
    </AccountProfile>
  );
};

export default UserProfile;
