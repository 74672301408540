import styled from "styled-components";

const SubAccountStyle = styled.div`
  margin: auto;
  font-family: "DM Sans";

  header {
    border-bottom: 0.5px solid #bebdbd;
    padding: 16px;
    /* padding-bottom: 20px; */

    .sub-account-heading {
      display: flex;
      justify-content: space-between;

      h3 {
        color: #0c0e13;
        font-size: 20px;
        font-weight: 500;
      }

      p {
        color: var(--grayscale-300, #667085);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  #total-section {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    /* padding-bottom: 10px; */

    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.24px;
    }

    p {
      font-size: 14px;

      font-weight: 400;
      line-height: 21px;
    }
  }

  #table-section {
    margin: 16px;
    margin-bottom: 0px;
    border: 0.5px solid #dbdfe7;
  }

  #button-section {
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }
`;

export { SubAccountStyle };
