import { createSlice } from "@reduxjs/toolkit";

const receiptSlice = createSlice({
  name: "receipt",
  initialState: {
    institutionId: "",
    recptAccountName: "",
    recptAccountNo: "",
    recptBankName: "",
    senderAccountName: "",
    senderAccountNo: "",
    senderBankName: "",
    amount: "",
    status: "",
    channel: "",
    narration: "",
    referenceNo: "",
    sessionId: "",
    dataAndTime: "",
    transactionType: "",
    oldBalance: "",
    newBalance: "",
  },
  reducers: {
    setReceipt: (state, action) => {
      state.institutionId = action.payload.institutionId;
      state.recptAccountName = action.payload.recptAccountName;
      state.recptAccountNo = action.payload.recptAccountNo;
      state.recptBankName = action.payload.recptBankName;
      state.senderAccountName = action.payload.senderAccountName;
      state.senderAccountNo = action.payload.senderAccountNo;
      state.senderBankName = action.payload.senderBankName;
      state.amount = action.payload.amount;
      state.status = action.payload.status;
      state.channel = action.payload.channel;
      state.narration = action.payload.narration;
      state.referenceNo = action.payload.referenceNo;
      state.sessionId = action.payload.sessionId;
      state.dataAndTime = action.payload.dataAndTime;
      state.transactionType = action.payload.transactionType;
      state.oldBalance = action.payload.oldBalance;
      state.newBalance = action.payload.newBalance;
    },
    clearReceipt: (state) => {
      state.institutionId = "";
      state.recptAccountName = "";
      state.recptAccountNo = "";
      state.recptBankName = "";
      state.senderAccountName = "";
      state.senderAccountNo = "";
      state.senderBankName = "";
      state.amount = "";
      state.status = "";
      state.channel = "";
      state.narration = "";
      state.referenceNo = "";
      state.sessionId = "";
      state.transactionType = "";
      state.dataAndTime = "";
      state.oldBalance = "";
      state.newBalance = "";
    },
  },
});

//this is for dispatch
export const { setReceipt, clearReceipt } = receiptSlice.actions;

//this is for reducer
export default receiptSlice.reducer;
