import React, { useRef } from "react";

//dependencies
import { useNavigate } from "react-router-dom";
//styled components
import { CustomCardStyle2 } from "./CardStyle";
//components
import CardListItem from "./CardListItem";
//svg components

import { ReactComponent as CloseIcon } from "../../../assets/icons/cardIcons/closeIcon.svg";
// import { ReactComponent as CardIcon } from "../../../assets/icons/cardIcons/cardIcon.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/cardIcons/cardIcon2.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/cardIcons/user-circle.svg";

//data

const AccountsTableCard = ({
  refVar,
  showContact,
  setShowContact,
  showCard,
  setShowCard,
  row,
}) => {
  const navigate = useNavigate();
  if (showContact === true) {
    return (
      <CustomCardStyle2 ref={refVar}>
        <li>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "auto",
            }}
          >
            <h5>{row.accountName}</h5>
          </div>
          <CloseIcon
            onClick={() => {
              setShowContact(false);
              setShowCard(false);
            }}
            style={{
              marginRight: "15px",
            }}
          />
        </li>
        <li>
          <CardListItem firsLine="Gender" secondLine={row.gender} />
        </li>
        <li>
          <CardListItem firsLine="State" secondLine={row.state} />
        </li>
        <li>
          <CardListItem firsLine="Zone" secondLine={row.lga} />
        </li>
        <li>
          <CardListItem firsLine="Ward" secondLine={row.ward} />
        </li>
        <li>
          <CardListItem firsLine="BVN" secondLine={row.Bvn ? row.Bvn : "N/A"} />
        </li>
      </CustomCardStyle2>
    );
  } else
    return (
      <CustomCardStyle2 ref={refVar}>
        <li className="kebab-list-item" onClick={() => setShowContact(true)}>
          <CardIcon style={{ marginRight: "18px", cursor: "default" }} />
          Additional Info
        </li>
        <li
          className="kebab-list-item"
          onClick={() =>
            navigate(`/accounts/${row.accountNumber}`, {
              state: {
                data: {
                  accountName: row.accountName,
                  accountNumber: row.accountNumber,
                },
              },
            })
          }
        >
          <UserIcon style={{ marginRight: "18px" }} />
          Transactions History
        </li>
      </CustomCardStyle2>
    );
};

export default AccountsTableCard;
