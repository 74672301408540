import React, { useEffect, useState } from "react";

//dependencies
import DataTable from "react-data-table-component";
import env from "../../env";
import { http } from "../../axios-https";
import moment from "moment";
//redux
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/slices/modalSlice";
//styled components
import { SubAccountStyle } from "./SubAccountStyle";
//components
import { ButtonWhiteOutline, ButtonGreenFilled } from "../button/Button";
//svg components
import { ReactComponent as CloseIcon } from "../../assets/icons/cardIcons/closeIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow-left.svg";
//data and utils

const SubAccountsComponent = () => {
  const [subAccountList, setSubAccountList] = useState([]);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const subAccountsDetails = useSelector((state) => state.subAccounts);
  const handleCloseModal = () => {
    dispatch(toggleModal());
  };

  const fetchSubAccounts = () => {
    setLoading(true);
    http
      .get(
        `${env.greenbanker_base_url}/institutions/accounts?institutionId=${subAccountsDetails.institutionId}`
      )
      .then((response) => {
        console.log(response.data.data);
        const listItem = response.data.data.map((item) => {
          return {
            accountName: item.name,
            accountNo: item.nuban,
            dateCreated: moment(item.createdAt).format("DD/MM/YY"),
          };
        });

        setSubAccountList([...listItem]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const paginationComponentOptions = {
    rowsPerPageText: "",
    rangeSeparatorText: "of",
    // selectAllRowsItem: true,
    // selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ACCOUNT NAME",
      selector: (row) => row.accountName,
    },
    {
      name: "ACCOUNT NUMBER",
      selector: (row) => row.accountNo,
    },
    {
      name: "DATE CREATED",
      selector: (row) => row.dateCreated,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "38px", // override the row height
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: "400",
        color: "#000",
      },

      // highlightOnHoverStyle: {
      // 	backgroundColor: "rgba(255, 250, 246, 0.5)",
      // 	borderBottomColor: "#D3D3D3",
      // 	outlineColor: "#D3D3D3",
      // },
    },
    headCells: {
      style: {
        paddingLeft: "16px", // override the cell padding for head cells
        paddingRight: "16px",
        backgroundColor: "#FDFFEA",
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: "500",
        color: "#2B3241",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "16px",
        minWidth: "100px",
      },
    },
  };

  useEffect(() => {
    fetchSubAccounts();
  }, []);

  return (
    <SubAccountStyle>
      <header>
        <div className="sub-account-heading">
          <div>
            <h3>{subAccountsDetails.institutionName}</h3>
            <p>All Accounts</p>
          </div>
          <div>
            <CloseIcon onClick={handleCloseModal} />
          </div>
        </div>
      </header>

      {/* TOTAL SECTION */}
      <section id="total-section">
        {loading ? <p>Fetching...</p> : <p>Total</p>}
        {!loading && <h3>{subAccountList.length}</h3>}
      </section>

      {/* TABLE SECTION */}

      <section id="table-section">
        <DataTable
          columns={columns}
          data={subAccountList}
          customStyles={customStyles}
          responsive={true}
          fixedHeader={true}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationPerPage={7}
          paginationRowsPerPageOptions={[7]}
          progressPending={loading}
        />
      </section>

      {/* BUTTON SECTION */}
      <section id="button-section">
        <ButtonWhiteOutline
          name="Back"
          icon={<LeftArrow style={{ marginTop: "4px", marginRight: "8px" }} />}
          style={{ width: "156px" }}
          handleClick={handleCloseModal}
        />
      </section>
    </SubAccountStyle>
  );
};

export default SubAccountsComponent;
