import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageCount: 0,
  currentPage: 1,
  rowsPerPage: 50,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,

  reducers: {
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    resetCurrentPage: (state, action) => {
      state.currentPage = 1;
    },
  },
});

//this is for dispatch
export const {
  setPageCount,
  setCurrentPage,
  setRowsPerPage,
  resetCurrentPage,
} = paginationSlice.actions;

//this is for reducer
export default paginationSlice.reducer;
