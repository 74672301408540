import React from "react";
import { ReactComponent as InstitutionLogo } from "../../assets/icons/sidebarMenuIcons/Icon1.svg";
import { ReactComponent as DashboardLogo } from "../../assets/icons/sidebarMenuIcons/Icon2.svg";
import { ReactComponent as AccountsLogo } from "../../assets/icons/sidebarMenuIcons/Icon3.svg";
import { ReactComponent as TransactionsLogo } from "../../assets/icons/sidebarMenuIcons/moneyIcon.svg";

const SidebarMenuLogo = ({ logoName }) => {
	switch (logoName) {
		case "Dashboard":
			return <DashboardLogo style={{ marginRight: "15px" }} />;
		case "Institutions":
			return <InstitutionLogo style={{ marginRight: "15px" }} />;
		case "Accounts":
			return <AccountsLogo style={{ marginRight: "15px" }} />;
		case "Transactions":
			return <TransactionsLogo style={{ marginRight: "15px" }} />;
	}
};

export default SidebarMenuLogo;
