import React, { useState, useEffect, useRef } from "react";

import Navbar from "../components/navbar/Navbar";
import SidebarMenu from "../components/sidebarMenu/SidebarMenu";
import PagesWrapper from "../pages/pagesWrapper/PagesWrapper";

const NavbarSidemenuHOC = (WrappedComponent) => {
  return function NavbarSidemenu(props) {
    const ref = useRef();
    const [showProfile, setShowProfile] = useState(false);

    //Check outsideclick
    useEffect(() => {
      const checkOutsideClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setShowProfile(false);
        }
      };
      document.addEventListener("mousedown", checkOutsideClick);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkOutsideClick);
      };
    }, [showProfile]);

    return (
      <div style={{ display: "flex" }}>
        <SidebarMenu />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "fafafa",
          }}
        >
          <Navbar
            showProfile={showProfile}
            setShowProfile={setShowProfile}
            navRef={ref}
          />
          <div
            style={{
              width: "calc(100vw - 216px)",
              top: "0",
              left: "14.1%",
              // zIndex: "0",
            }}
          >
            <WrappedComponent {...props} />
          </div>
        </div>
      </div>
    );
  };
};

export default NavbarSidemenuHOC;
