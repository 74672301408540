import React, { useEffect, useState } from "react";

//dependencies
import DataTable from "react-data-table-component";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setRowsPerPage,
  setCurrentPage,
  resetCurrentPage,
} from "../../redux/slices/paginationSlice";

//styled components
import { TableStyle } from "../TableStyle";

//custom hooks
import useGetInstitutionsApi from "../../customHooks/Institutions/useGetInstitutionsApi";
import useGetInstitutionColumns from "../../customHooks/Institutions/useGetInstitutionColumns";

//components
// import TestHOC from "../../HOC/NavbarSidemenuHOC";
import NavbarSideMenuHOC from "../../HOC/NavbarSidemenuHOC";
import Input from "../../components/input/Input";
import SubAccountsComponent from "../../components/subAcountsComponent/SubAccountsComponent";
import { Modal } from "../../modals/Modal";

//svg components
import { ReactComponent as SearchIcon } from "../../assets/icons/TableIcons/searchIcon.svg";

//data and utils
import { institutionTableCustomStyles } from "../../data/tableStyles";
import { paginationOptions } from "../../data/data";

const Institutions = () => {
  //redux dispatch and selectors
  const dispatch = useDispatch();
  const paginationParams = useSelector((state) => state.pagination);

  const { loading, institutionList, totalInstitutions, pageCount } =
    useGetInstitutionsApi();

  //fetch table column
  const { institutionTableColumns } = useGetInstitutionColumns();

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onChangeRowsPerPage = (rowsPerPageValue) => {
    setRowsPerPage(rowsPerPageValue);
    setCurrentPage(1); // Reset current page to the first page
  };

  return (
    <>
      <Modal>
        <SubAccountsComponent />
      </Modal>
      <TableStyle>
        {/* {console.log(institutionList)} */}
        <div
          className="table-title"
          onClick={() => console.log(paginationParams)}
        >
          <h1>All Institutions</h1>
          <p>{totalInstitutions} institutions</p>
        </div>

        <Input
          placeholder={"Search by institution name or institution ID"}
          logo={<SearchIcon />}
          inputStyle={{ marginBottom: "24px" }}
        />

        <div style={{ border: "1px solid #EFF1F4", overflow: "visible" }}>
          <DataTable
            columns={institutionTableColumns}
            data={institutionList}
            customStyles={institutionTableCustomStyles}
            responsive
            noTableStyles
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={pageCount * rowsPerPage}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[50]}
            paginationComponentOptions={paginationOptions}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </div>
      </TableStyle>
    </>
  );
};

export default NavbarSideMenuHOC(Institutions);
